// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogoInfo_logoInfoWrapper__vlY5t {
  color: rgba(232, 216, 216, 0.5);
  border-bottom: 1px solid #292e33;
  padding-bottom: 56px;
  height: 72px;
}
.LogoInfo_logo_content__U3o4w {
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
}
.LogoInfo_logo_content__U3o4w .LogoInfo_logo__kPeNp {
  margin-right: 32px;
}
.LogoInfo_logo_content__U3o4w p {
  color: rgb(103, 109, 115);
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/componentsFooter/LogoInfo/LogoInfo.module.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EAEA,gCAAA;EAEA,oBAAA;EAEA,YAAA;AAFF;AAWA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,oBAAA;AATF;AAWE;EACE,kBAAA;AATJ;AAYE;EACE,yBAAA;AAVJ","sourcesContent":[".logoInfoWrapper {\n  color: rgba(232, 216, 216, 0.5);\n\n  border-bottom: 1px solid #292e33;\n\n  padding-bottom: 56px;\n\n  height: 72px;\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n  }\n}\n\n.logo_content {\n  display: flex;\n  align-items: center;\n  padding-top: 16px;\n  padding-bottom: 16px;\n\n  .logo {\n    margin-right: 32px;\n  }\n\n  p {\n    color: rgba(103, 109, 115, 1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoInfoWrapper": `LogoInfo_logoInfoWrapper__vlY5t`,
	"logo_content": `LogoInfo_logo_content__U3o4w`,
	"logo": `LogoInfo_logo__kPeNp`
};
export default ___CSS_LOADER_EXPORT___;
