// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title_title__CL1FB {
  display: flex;
  position: relative;
  margin-bottom: 48px;
  gap: 10px;
}
.title_title__CL1FB span {
  color: #C2C7CC;
  font-weight: 300;
  font-size: 24px;
  display: inline-block;
  position: relative;
  top: -15px;
}

.title_decorArrowWrapper__OtfgU {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  margin-left: 20px;
}

.title_number__hzyGg {
  position: relative;
  font-size: 48px;
  font-weight: 700;
  color: #292e33;
  margin-right: 10px;
}

.title_text__5wGCH {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 5%;
  color: #292e33;
  text-transform: uppercase;
}

@media (max-width: 900px) {
  .title_text__5wGCH::before, .title_text__5wGCH::after {
    display: none;
  }
}
@media (max-width: 475px) {
  .title_title__CL1FB .title_text__5wGCH,
  .title_title__CL1FB .title_number__hzyGg {
    font-size: 34px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Title/title.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,SAAA;AAAJ;AAEI;EACE,cAAA;EACA,gBAAA;EACA,eAAA;EACA,qBAAA;EACA,kBAAA;EACA,UAAA;AAAN;;AAIE;EACE,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,6BAAA;EACA,iBAAA;AADJ;;AAIE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AADJ;;AAIE;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,cAAA;EACA,yBAAA;AADJ;;AAIE;EAEI;IACE,aAAA;EAFN;AACF;AAME;EAEI;;IAEE,eAAA;EALN;AACF","sourcesContent":["\n  .title {\n    display: flex;\n    position: relative;\n    margin-bottom: 48px;\n    gap: 10px;\n\n    span {\n      color: #C2C7CC; \n      font-weight: 300;\n      font-size: 24px; \n      display: inline-block; \n      position: relative; \n      top: -15px; \n    }\n  }\n\n  .decorArrowWrapper {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n    justify-content: space-around;\n    margin-left: 20px;\n  }\n\n  .number {\n    position: relative;\n    font-size: 48px;\n    font-weight: 700;\n    color: #292e33;\n    margin-right: 10px;\n  }\n\n  .text {\n    font-size: 48px;\n    font-weight: 700;\n    letter-spacing: 5%;\n    color: #292e33;\n    text-transform: uppercase;\n  }\n\n  @media (max-width: 900px) { \n    .text {\n      &::before, &::after {\n        display: none;\n      }\n    }\n  }\n\n  @media (max-width: 475px) { \n    .title {\n      .text,\n      .number {\n        font-size: 34px;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `title_title__CL1FB`,
	"decorArrowWrapper": `title_decorArrowWrapper__OtfgU`,
	"number": `title_number__hzyGg`,
	"text": `title_text__5wGCH`
};
export default ___CSS_LOADER_EXPORT___;
