// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

input,
button,
textarea {
  outline: none;
}

.Container_container__MM274 {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 120px;
  padding-right: 120px;
  overflow: hidden;
}
@media (max-width: 1360px) {
  .Container_container__MM274 {
    padding-left: 120px;
    padding-right: 120px;
  }
}
@media (max-width: 768px) {
  .Container_container__MM274 {
    padding-left: 24px;
    padding-right: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Container/Container.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;AACF;;AAEA;;EAEE,gBAAA;AACF;;AAEA;EACE,qBAAA;EACA,cAAA;AACF;;AAEA;;;EAGE,cAAA;EACA,qBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;;;EAGE,aAAA;AACF;;AAEA;EACE,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;AACF;AACE;EARF;IAUI,mBAAA;IACA,oBAAA;EACF;AACF;AACE;EAdF;IAeI,kBAAA;IACA,mBAAA;EAEF;AACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nul,\nol {\n  list-style: none;\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n}\n\na:hover,\na:visited,\na:active {\n  color: inherit;\n  text-decoration: none; \n}\n\nimg {\n  max-width: 100%;\n}\n\ninput,\nbutton,\ntextarea {\n  outline: none;\n}\n\n.container {\n  max-width: 1920px;\n  margin-left: auto;\n  margin-right: auto;\n  padding-left: 120px;\n  padding-right: 120px;\n  overflow: hidden;\n\n  @media (max-width: 1360px) {\n   \n    padding-left: 120px;\n    padding-right: 120px;\n  }\n\n  @media (max-width: 768px) {\n    padding-left: 24px;\n    padding-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__MM274`
};
export default ___CSS_LOADER_EXPORT___;
