// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TitleContact_contactsWrapper_title__IDxgh {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
  margin-bottom: 16px;
  padding-right: 32px;
}
.TitleContact_contactsWrapper_title__IDxgh h2 {
  display: block;
  color: var(--5, #c2c7cc);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
@media (max-width: 768px) {
  .TitleContact_contactsWrapper_title__IDxgh {
    margin-bottom: 32px;
    width: 100%;
    padding-right: 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/componentsFooter/Contacts/TitleContact/TitleContact.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,UAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,cAAA;EACA,wBAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AASE;EAzBF;IA0BI,mBAAA;IACJ,WAAA;IACA,kBAAA;EANE;AACF","sourcesContent":[".contactsWrapper_title {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 25%;\n  margin-bottom: 16px;\n  padding-right: 32px;\n\n  h2 {\n    display: block;\n    color: var(--5, #c2c7cc);\n    font-family: Open Sans;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px;\n  }\n\n\n  \n  @media (max-width: 1360px) {\n   \n\n  }\n\n  @media (max-width: 768px) {\n    margin-bottom: 32px;\nwidth: 100%;\npadding-right: 0px;\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactsWrapper_title": `TitleContact_contactsWrapper_title__IDxgh`
};
export default ___CSS_LOADER_EXPORT___;
