// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoAboutUs_wrapperList__HMWUx {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 25%;
}
.InfoAboutUs_wrapperList__HMWUx li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #c2c7cc;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-right: 32px;
}
.InfoAboutUs_wrapperList__HMWUx li:not(:last-child) {
  margin-bottom: 16px;
}
.InfoAboutUs_wrapperList__HMWUx li a {
  display: flex;
  align-self: center;
  color: #c2c7cc;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
}
@media (max-width: 768px) {
  .InfoAboutUs_wrapperList__HMWUx li {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 768px) {
  .InfoAboutUs_wrapperList__HMWUx {
    margin-bottom: 56px;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/componentsFooter/AboutUs/InfoAboutUs/InfoAboutUs.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,2BAAA;EACA,sBAAA;EACA,UAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,cAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACJ;AACI;EACE,mBAAA;AACN;AAEI;EACE,aAAA;EACA,kBAAA;EACA,cAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;AAAN;AASI;EAlCF;IAqCI,WAAA;IACA,UAAA;EARJ;AACF;AAiBE;EAtDF;IAuDI,mBAAA;IACA,WAAA;EAdF;AACF","sourcesContent":[".wrapperList {\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  width: 25%;\n\n  li {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    color: #c2c7cc;\n    font-family: Open Sans;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px;\n    padding-right: 32px;\n\n    &:not(:last-child) {\n      margin-bottom: 16px; \n    }\n\n    a {\n      display: flex;\n      align-self: center;\n      color: #c2c7cc;\n      font-family: Open Sans;\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 24px;\n      text-decoration: none;\n    }\n\n\n\n    @media (max-width: 1360px) {\n\n    }\n  \n    @media (max-width: 768px) {\n      // background-color: tomato;\n      // border: 1px solid tomato;\n      width: 100%;\n      padding: 0;\n    }\n\n\n  }\n\n  @media (max-width: 1360px) {\n\n  }\n\n  @media (max-width: 768px) {\n    margin-bottom: 56px;\n    width: 100%;\n    // display: none; \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperList": `InfoAboutUs_wrapperList__HMWUx`
};
export default ___CSS_LOADER_EXPORT___;
