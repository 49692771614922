// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LectureSchedule_LectureWrapper__SaU\\+s {
  padding-top: 75px;
  padding-bottom: 125px;
  display: flex;
}
.LectureSchedule_LectureWrapper__SaU\\+s .LectureSchedule_LectureList__b1Juc {
  display: flex;
  flex-wrap: wrap;
  gap: 80px;
}
@media (max-width: 768px) {
  .LectureSchedule_LectureWrapper__SaU\\+s .LectureSchedule_LectureList__b1Juc li {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/lectureSchedule/LectureSchedule.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,qBAAA;EACA,aAAA;AACF;AACE;EACE,aAAA;EACA,eAAA;EACA,SAAA;AACJ;AAKM;EADF;IAEI,WAAA;EAFN;AACF","sourcesContent":[".LectureWrapper {\n  padding-top: 75px;\n  padding-bottom: 125px;\n  display: flex;\n\n  .LectureList {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 80px;\n    // width: 100%;\n    // border: 1px solid green;\n \n\n    li {\n      @media (max-width: 768px) {\n        width: 100%;\n      }\n    }\n  }\n\n  // @media (max-width: 768px) {\n  //   width: 100%;\n  // }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LectureWrapper": `LectureSchedule_LectureWrapper__SaU+s`,
	"LectureList": `LectureSchedule_LectureList__b1Juc`
};
export default ___CSS_LOADER_EXPORT___;
