import css from "./Table4.module.scss";

const card = [
  {
    id: 1,
    img: 'card__clock-1',
    time: '20',
    speed: '8,0',
  },
  {
    id: 2,
    img: 'card__clock-2',
    time: '25',
    speed: '7,5',
  },
  {
    id: 3,
    img: 'card__clock-3',
    time: '30',
    speed: '6,0',
  },
  {
    id: 4,
    img: 'card__clock-4',
    time: '40',
    speed: '4,5',
  },
  {
    id: 5,
    img: 'card__clock-5',
    time: '50',
    speed: '3,6',
  },
  {
    id: 6,
    img: 'card__clock-6',
    time: '60',
    speed: '3,0',
  },
]


export const Table4 = () => {


  return (
    <>
      <div className={css['table']}>
        {card.map((item, index) => (
          (item.id === 3) ? (
            <div className={css['table__card--blue']}>
              <div className={css['card__clock-3']}></div>
              <div>
                <div className={css['card__time--blue']}>{item.time} <span style={{ color: '#c2c7cc' }}>сек.</span></div>
                <div className={css['card__speed']}>{item.speed} <span style={{ color: '#c2c7cc' }}>км/год.</span></div>
              </div>
              <div className={css['card__range']}>
                <div className={css['card__dumbell']}>
                  <div className={css['card__dumbell--left-bl']}></div>
                  <div className={css['card__dumbell--line-bl']}></div>
                  <div className={css['card__dumbell--right-bl']}></div>
                </div>
                <div className={css['card__text']}>
                  <div className={css['card__text--start']}>
                    0 <span style={{ color: '#c2c7cc' }}>м</span>
                  </div>
                  <div className={css['card__text--end']}>
                    50 <span style={{ color: '#c2c7cc' }}>м</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={css['table__card']}>
              <div className={css[`${item.img}`]}></div>
              <div>
                <div className={css['card__time']}>{item.time} <span style={{ color: '#c2c7cc' }}>сек.</span></div>
                <div className={css['card__speed']}>{item.speed} <span style={{ color: '#c2c7cc' }}>км/год.</span></div>
              </div>
              <div className={css['card__range']}>
                <div className={css['card__dumbell']}>
                  <div className={css['card__dumbell--left']}></div>
                  <div className={css['card__dumbell--line']}></div>
                  <div className={css['card__dumbell--right']}></div>
                </div>
                <div className={css['card__text']}>
                  <div className={css['card__text--start']}>
                    0 <span style={{ color: '#c2c7cc' }}>м</span>
                  </div>
                  <div className={css['card__text--end']}>
                    50 <span style={{ color: '#c2c7cc' }}>м</span>
                  </div>
                </div>
              </div>
            </div>
          )
        ))}
      </div>
    </>
  )
}


{/* <div className={css['table']}>
<div className={css['table__card']}>
  <div className={css['card__clock-1']}></div>
  <div>
    <div className={css['card__time']}>20 <span style={{ color: '#c2c7cc' }}>сек.</span></div>
    <div className={css['card__speed']}>8,0 <span style={{ color: '#c2c7cc' }}>км/год.</span></div>
  </div>
  <div className={css['card__range']}>
    <div className={css['card__dumbell']}>
      <div className={css['card__dumbell--left']}></div>
      <div className={css['card__dumbell--line']}></div>
      <div className={css['card__dumbell--right']}></div>
    </div>
    <div className={css['card__text']}>
      <div className={css['card__text--start']}>
        0 <span style={{ color: '#c2c7cc' }}>м</span>
      </div>
      <div className={css['card__text--end']}>
        50 <span style={{ color: '#c2c7cc' }}>м</span>
      </div>
    </div>
  </div>
</div>
<div className={css['table__card']}>
  <div className={css['card__clock-2']}></div>
  <div>
    <div className={css['card__time']}>25 <span style={{ color: '#c2c7cc' }}>сек.</span></div>
    <div className={css['card__speed']}>7,5 <span style={{ color: '#c2c7cc' }}>км/год.</span></div>
  </div>
  <div className={css['card__range']}>
    <div className={css['card__dumbell']}>
      <div className={css['card__dumbell--left']}></div>
      <div className={css['card__dumbell--line']}></div>
      <div className={css['card__dumbell--right']}></div>
    </div>
    <div className={css['card__text']}>
      <div className={css['card__text--start']}>
        0 <span style={{ color: '#c2c7cc' }}>м</span>
      </div>
      <div className={css['card__text--end']}>
        50 <span style={{ color: '#c2c7cc' }}>м</span>
      </div>
    </div>
  </div>
</div>
<div className={css['table__card--blue']}>
  <div className={css['card__clock-3']}></div>
  <div>
    <div className={css['card__time--blue']}>30 <span style={{ color: '#c2c7cc' }}>сек.</span></div>
    <div className={css['card__speed']}>6,0 <span style={{ color: '#c2c7cc' }}>км/год.</span></div>
  </div>
  <div className={css['card__range']}>
    <div className={css['card__dumbell']}>
      <div className={css['card__dumbell--left']}></div>
      <div className={css['card__dumbell--line']}></div>
      <div className={css['card__dumbell--right']}></div>
    </div>
    <div className={css['card__text']}>
      <div className={css['card__text--start']}>
        0 <span style={{ color: '#c2c7cc' }}>м</span>
      </div>
      <div className={css['card__text--end']}>
        50 <span style={{ color: '#c2c7cc' }}>м</span>
      </div>
    </div>
  </div>
</div>
<div className={css['table__card']}>
  <div className={css['card__clock-4']}></div>
  <div>
    <div className={css['card__time']}>40 <span style={{ color: '#c2c7cc' }}>сек.</span></div>
    <div className={css['card__speed']}>4,5 <span style={{ color: '#c2c7cc' }}>км/год.</span></div>
  </div>
  <div className={css['card__range']}>
    <div className={css['card__dumbell']}>
      <div className={css['card__dumbell--left']}></div>
      <div className={css['card__dumbell--line']}></div>
      <div className={css['card__dumbell--right']}></div>
    </div>
    <div className={css['card__text']}>
      <div className={css['card__text--start']}>
        0 <span style={{ color: '#c2c7cc' }}>м</span>
      </div>
      <div className={css['card__text--end']}>
        50 <span style={{ color: '#c2c7cc' }}>м</span>
      </div>
    </div>
  </div>
</div>
<div className={css['table__card']}>
  <div className={css['card__clock-5']}></div>
  <div>
    <div className={css['card__time']}>50 <span style={{ color: '#c2c7cc' }}>сек.</span></div>
    <div className={css['card__speed']}>3,6 <span style={{ color: '#c2c7cc' }}>км/год.</span></div>
  </div>
  <div className={css['card__range']}>
    <div className={css['card__dumbell']}>
      <div className={css['card__dumbell--left']}></div>
      <div className={css['card__dumbell--line']}></div>
      <div className={css['card__dumbell--right']}></div>
    </div>
    <div className={css['card__text']}>
      <div className={css['card__text--start']}>
        0 <span style={{ color: '#c2c7cc' }}>м</span>
      </div>
      <div className={css['card__text--end']}>
        50 <span style={{ color: '#c2c7cc' }}>м</span>
      </div>
    </div>
  </div>
</div>
<div className={css['table__card']}>
  <div className={css['card__clock-6']}></div>
  <div>
    <div className={css['card__time']}>60 <span style={{ color: '#c2c7cc' }}>сек.</span></div>
    <div className={css['card__speed']}>3,0 <span style={{ color: '#c2c7cc' }}>км/год.</span></div>
  </div>
  <div className={css['card__range']}>
    <div className={css['card__dumbell']}>
      <div className={css['card__dumbell--left']}></div>
      <div className={css['card__dumbell--line']}></div>
      <div className={css['card__dumbell--right']}></div>
    </div>
    <div className={css['card__text']}>
      <div className={css['card__text--start']}>
        0 <span style={{ color: '#c2c7cc' }}>м</span>
      </div>
      <div className={css['card__text--end']}>
        50 <span style={{ color: '#c2c7cc' }}>м</span>
      </div>
    </div>
  </div>
</div>
</div> */}