// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardEmployee_cardWrapper__K-ezn {
  width: 370px;
}
.cardEmployee_cardWrapper__K-ezn .cardEmployee_decorV__rPx5T {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.cardEmployee_cardWrapper__K-ezn .cardEmployee_photoWrapper__hE2bH {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.cardEmployee_cardWrapper__K-ezn .cardEmployee_photoWrapper__hE2bH .cardEmployee_photo__X0H\\+3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cardEmployee_cardWrapper__K-ezn h3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: rgb(242, 242, 243);
}
.cardEmployee_cardWrapper__K-ezn h3 span {
  font-weight: 400;
}
.cardEmployee_cardWrapper__K-ezn p {
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgb(242, 242, 243);
}`, "",{"version":3,"sources":["webpack://./src/components/cardEmployees/cardEmployee.module.scss"],"names":[],"mappings":"AACA;EACC,YAAA;AAAD;AAGE;EACE,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AADJ;AAKE;EACE,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAHJ;AAKI;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AAHN;AAWA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;EAEA,sBAAA;EACF,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;AAVA;AAYA;EACE,gBAAA;AAVF;AAeA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;AAbA","sourcesContent":["\n.cardWrapper {\n width: 370px;\n\n  \n  .decorV {\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n    margin-bottom: 30px;\n  }\n  \n  \n  .photoWrapper {\n    position: relative;\n    display: flex;\n    justify-content: center;\n    margin-bottom: 50px;\n    \n    .photo {\n      position: absolute;\n      top: 50%; \n      left: 50%; \n      transform: translate(-50%, -50%);\n    }\n\n\n    \n  }\n\n  \nh3 {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 30px;\n\n  font-family: Open Sans;\nfont-size: 24px;\nfont-weight: 800;\nline-height: 32px;\nletter-spacing: 0em;\ntext-align: center;\ncolor: rgba(242, 242, 243, 1);\n\nspan {\n  font-weight: 400;\n}\n\n}\n\np {\ntext-align: center;\nfont-family: Open Sans;\nfont-size: 16px;\nfont-weight: 400;\nline-height: 24px;\nletter-spacing: 0em;\ntext-align: center;\ncolor: rgba(242, 242, 243, 1);\n\n}\n  \n  \n  \n  @media (max-width: 1360px) {\n    \n}\n\n@media (max-width: 768px) {\n  \n}\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardWrapper": `cardEmployee_cardWrapper__K-ezn`,
	"decorV": `cardEmployee_decorV__rPx5T`,
	"photoWrapper": `cardEmployee_photoWrapper__hE2bH`,
	"photo": `cardEmployee_photo__X0H+3`
};
export default ___CSS_LOADER_EXPORT___;
