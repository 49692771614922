// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LecturesLaboratory_LectureWrapper__F3ror {
  padding-top: 58px;
  padding-bottom: 125px;
}`, "",{"version":3,"sources":["webpack://./src/views/lecturesLaboratory/LecturesLaboratory.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,qBAAA;AACF","sourcesContent":[".LectureWrapper {\n  padding-top: 58px;\n  padding-bottom: 125px;\n  // display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LectureWrapper": `LecturesLaboratory_LectureWrapper__F3ror`
};
export default ___CSS_LOADER_EXPORT___;
