// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  margin-bottom: 60px;
  border-collapse: separate;
  border-spacing: 10px;
}
table th {
  padding: 20px 0;
}
table td span {
  color: #292E33;
  border-bottom: 1px solid #959EA6;
  padding: 20px 0;
  text-align: left;
}
table td.tabeltemplate_light__7h4Ta,
table td.tabeltemplate_middle__PUyBK,
table td.tabeltemplate_hard__S2jSG {
  border-top: 1px dashed;
  text-align: right;
  font-weight: 700;
  padding: 15px;
}
table tr:last-child td.tabeltemplate_light__7h4Ta,
table tr:last-child td.tabeltemplate_middle__PUyBK,
table tr:last-child td.tabeltemplate_hard__S2jSG {
  border-bottom: 1px dashed;
}
table span {
  font-size: 16px;
  font-weight: 400;
  color: #676D73;
}

.tabeltemplate_light__7h4Ta {
  color: #C19C65;
  font-weight: 700;
}

.tabeltemplate_middle__PUyBK {
  color: #845713;
  font-weight: 700;
}

.tabeltemplate_hard__S2jSG {
  color: #292E33;
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/Soil/Table/tabeltemplate.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,yBAAA;EACA,oBAAA;AACJ;AACI;EACI,eAAA;AACR;AAGQ;EACI,cAAA;EACA,gCAAA;EACA,eAAA;EACA,gBAAA;AADZ;AAKI;;;EAGI,sBAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;AAHR;AASY;;;EAGI,yBAAA;AAPhB;AAYI;EACI,eAAA;EACA,gBAAA;EACA,cAAA;AAVR;;AAcA;EACI,cAAA;EACA,gBAAA;AAXJ;;AAaA;EACI,cAAA;EACA,gBAAA;AAVJ;;AAYA;EACI,cAAA;EACA,gBAAA;AATJ","sourcesContent":["table {\n    margin-bottom: 60px;\n    border-collapse: separate;\n    border-spacing: 10px;\n\n    th {\n        padding: 20px 0;\n    }\n\n    td {\n        span {\n            color: #292E33;\n            border-bottom: 1px solid #959EA6;\n            padding: 20px 0;\n            text-align: left;\n        }\n    }\n\n    td.light,\n    td.middle,\n    td.hard {\n        border-top: 1px dashed;\n        text-align: right;\n        font-weight: 700;\n        padding: 15px;\n    }\n\n    tr {\n        \n        &:last-child {\n            td.light,\n            td.middle,\n            td.hard {\n                border-bottom: 1px dashed;\n            }\n        }\n    }\n\n    span {\n        font-size: 16px;\n        font-weight: 400;\n        color: #676D73;\n    }\n}\n\n.light {\n    color: #C19C65;\n    font-weight: 700;\n}\n.middle {\n    color: #845713;\n    font-weight: 700;\n}\n.hard {\n    color: #292E33;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"light": `tabeltemplate_light__7h4Ta`,
	"middle": `tabeltemplate_middle__PUyBK`,
	"hard": `tabeltemplate_hard__S2jSG`
};
export default ___CSS_LOADER_EXPORT___;
