// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.powerelem_element__JORsW {
  padding: 20px;
  min-width: 200px;
  border: 1px solid #FFFFFF;
  position: relative;
  text-align: center;
}

.powerelem_image__Z8P\\+F {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -40%;
}

.powerelem_text__aoFDl {
  color: #292E33;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.powerelem_text__aoFDl span {
  color: #C2C7CC;
}`, "",{"version":3,"sources":["webpack://./src/components/Power/powerelem.module.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,gBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,SAAA;EACA,2BAAA;EACA,SAAA;AAAJ;;AAGA;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAAJ;AAEI;EACI,cAAA;AAAR","sourcesContent":["\n.element {\n    padding: 20px;\n    min-width: 200px;\n    border: 1px solid #FFFFFF;\n    position: relative;\n    text-align: center;\n}\n\n.image {\n    position: absolute;\n    left: 50%;\n    transform: translateX(-50%);\n    top: -40%;\n}\n\n.text {\n    color: #292E33;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 600;\n\n    span {\n        color: #C2C7CC;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element": `powerelem_element__JORsW`,
	"image": `powerelem_image__Z8P+F`,
	"text": `powerelem_text__aoFDl`
};
export default ___CSS_LOADER_EXPORT___;
