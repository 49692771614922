import React from "react";
import { Link } from "react-router-dom";
import css from "./popup.module.scss";

export const ClientPopUp = ({ type, dataCabinet, cabinetName, translate }) => {
  return (
    <div className={`pop-inner ${type.id}`}>
    <div className={css["popup"]}>
      <div className={css["popup__inner"]}>
        <div className={css["popup__head"]}>
          <div className="room__title pop">
            <div className="room__title--img"></div>
            <div className="room__title--text">
              {cabinetName !== undefined && cabinetName.split(" ")[0]} &nbsp; 
              <span style={{ fontWeight: "700" }}>
                {cabinetName !== undefined && cabinetName.split(" ")[1]}
              </span>
            </div>
            {type.path === "/clients" && <span> &nbsp; (для гуртового покупця)</span>}
          </div>
          <div className="room__title--links">
            {type.path === "/workers" || type.path === "/partners" ? (
              <>
                <Link
                  to={
                    type !== undefined && dataCabinet[type.path] !== undefined
                      ? dataCabinet[type.path].url1
                      : "#"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="room__enter"
                >
                  <div className="room__enter--img"></div>
                  <div className="room__enter--text">
                    {translate("header.cabinets.follow")}
                  </div>
                </Link>
              </>
            ) : (
              <>
                {" "}
                <Link
                  to={
                    type !== undefined && dataCabinet[type.path] !== undefined
                      ? dataCabinet[type.path].url1
                      : "#"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="room__enter"
                >
                  <div className="room__enter--img"></div>
                  <div className="room__enter--text">
                    {translate("header.cabinets.login")}
                  </div>
                </Link>
                <Link
                  to={
                    type !== undefined && dataCabinet[type.path] !== undefined
                      ? dataCabinet[type.path].url2
                      : "#"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  className="room__reg"
                >
                  <div className="room__reg--img"></div>
                  <div className="room__reg--text">
                    {translate("header.cabinets.registration")}
                  </div>
                </Link>
              </>
            )}
          </div>
        </div>
        {type.path === "/clients" && (
          <div className={css["popup-main"]}>
            <div
              className={css["popup-main__item"]}
              dangerouslySetInnerHTML={{
                __html: translate("header.cabinets.clientsD.text1"),
              }}
            ></div>
            <div
              className={css["popup-main__item"]}
              dangerouslySetInnerHTML={{
                __html: translate("header.cabinets.clientsD.text2"),
              }}
            ></div>
            <div
              className={css["popup-main__item"]}
              dangerouslySetInnerHTML={{
                __html: translate("header.cabinets.clientsD.text3"),
              }}
            ></div>
          </div>
        )}

        {type.path === "/drivers" && (
          <div className={css["popup-main"]}>
            <div className={css["popup-main__item"]}>
              <p>{translate("header.cabinets.driversD.text1")}</p>
            </div>
            <div className={css["popup-main__item"]}>
              <p>{translate("header.cabinets.driversD.text2")}</p>
            </div>
          </div>
        )}

        {type.path === "/fermers" && (
          <div className={css["popup-main"]}>
            <div className={css["popup-main__item"]}>
              <p>{translate("header.cabinets.farmerD.text1")}</p>
            </div>
            <div className={css["popup-main__item"]}>
              <p>{translate("header.cabinets.farmerD.text2")}</p>
              <p>{translate("header.cabinets.farmerD.text3")}</p>
            </div>
          </div>
        )}

        {type.path === "/workers" && (
          <div className={css["popup-main"]}>
            <div className={css["popup-main__item"]}>
              <p>{translate("header.cabinets.employeeD")}</p>
            </div>
          </div>
        )}

        {type.path === "/partners" && (
          <div className={css["popup-main"]}>
            <div className={css["popup-main__item"]}>
              <p>{translate("header.cabinets.partnersD.text1")}</p>
            </div>
            <div className={css["popup-main__item"]}>
              <p>{translate("header.cabinets.partnersD.text2")}</p>
            </div>
            <div className={css["popup-main__item"]}>
              <p>{translate("header.cabinets.partnersD.text3")}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  </div>
  );
};
