// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GoogleMaps_mapWrapper__6ncVw {
  position: relative;
  width: 100%;
  max-height: 350px;
}
.GoogleMaps_mapWrapper__6ncVw iframe {
  width: 100%;
  height: 100%;
  border: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/GoogleMaps/GoogleMaps.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,iBAAA;AACF;AACE;EAEE,WAAA;EACA,YAAA;EACA,SAAA;AAAJ","sourcesContent":[".mapWrapper {\n  position: relative;\n  width: 100%;\n  max-height: 350px;\n\n  iframe {\n\n    width: 100%;\n    height: 100%;\n    border: 0;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapWrapper": `GoogleMaps_mapWrapper__6ncVw`
};
export default ___CSS_LOADER_EXPORT___;
