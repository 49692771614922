// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SocialList_contacts_social__wrapper__ge6lU {
  flex: 0.5 1;
}
.SocialList_contacts_social__wrapper__ge6lU .SocialList_messageIcon__HL7lP {
  margin-bottom: 8px;
  fill: rgb(194, 199, 204);
}
.SocialList_contacts_social__wrapper__ge6lU p {
  color: #c2c7cc;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  margin-bottom: 18px;
}
.SocialList_contacts_social__wrapper__ge6lU .SocialList_social__list__Pq1mh {
  display: flex;
}
.SocialList_contacts_social__wrapper__ge6lU .SocialList_social__list__Pq1mh li {
  height: 20px;
}
.SocialList_contacts_social__wrapper__ge6lU .SocialList_social__list__Pq1mh .SocialList_icons__q\\+jSi {
  fill: rgb(194, 199, 204);
  margin-right: 28px;
  width: 20px;
  height: 20px;
}
@media (max-width: 768px) {
  .SocialList_contacts_social__wrapper__ge6lU .SocialList_social__list__Pq1mh {
    margin: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/componentsFooter/Contacts/SocialList/SocialList.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AAGE;EACE,kBAAA;EACA,wBAAA;AADJ;AAGE;EACE,cAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AADJ;AAIE;EACE,aAAA;AAFJ;AAII;EACE,YAAA;AAFN;AAKI;EACE,wBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AAHN;AAYI;EApBF;IAsBI,SAAA;EAVJ;AACF","sourcesContent":[".contacts_social__wrapper {\n  flex: 0.5;\n  // border: 1px solid tomato;\n\n\n  .messageIcon {\n    margin-bottom: 8px;\n    fill: rgba(194, 199, 204, 1);\n  }\n  p {\n    color: #c2c7cc;\n    font-family: Open Sans;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 14px;\n    margin-bottom: 18px;\n  }\n\n  .social__list {\n    display: flex;\n\n    li {\n      height: 20px;\n    }\n    \n    .icons {\n      fill: rgba(194, 199, 204, 1);\n      margin-right: 28px;\n      width: 20px;\n      height: 20px;\n    }\n    \n    \n    \n    \n    @media (max-width: 1360px) {\n    }\n    \n    @media (max-width: 768px) {\n      \n      margin: 0;\n    }\n  }\n\n\n\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contacts_social__wrapper": `SocialList_contacts_social__wrapper__ge6lU`,
	"messageIcon": `SocialList_messageIcon__HL7lP`,
	"social__list": `SocialList_social__list__Pq1mh`,
	"icons": `SocialList_icons__q+jSi`
};
export default ___CSS_LOADER_EXPORT___;
