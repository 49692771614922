import css from "./Table3.module.scss";


export const Table3 = () => {

  return (
    <>
      <div className={css['table']}>

        <div className={css['col-1']}>
          <div className={css['col-1__subtitle']}>
            Польова
            <br />
            схожість
          </div>
          <div className={css['col-1__row-1']}>
            <div className={css['col-1__row-1--text']}>50 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span></div>
            <div className={css['col-1__row-1--pag']}>
              <div className={css['col-1__row-1--pag-1']}></div>
              <div className={css['col-1__row-1--pag-2']}></div>
              <div className={css['col-1__row-1--pag-2']}></div>
              <div className={css['col-1__row-1--pag-3']}></div>
              <div className={css['col-1__row-1--pag-3']}></div>
              <div className={css['col-1__row-1--pag-5']}></div>
              <div className={css['col-1__row-1--pag-5']}></div>
              <div className={css['col-1__row-1--pag-5']}></div>
              <div className={css['col-1__row-1--pag-5']}></div>
              <div className={css['col-1__row-1--pag-5']}></div>
            </div>
          </div>
          <div className={css['col-1__row-2']}>
            <div className={css['col-1__row-2--text']}>55 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span></div>
            <div className={css['col-1__row-1--pag']}>
              <div className={css['col-1__row-2--pag-1']}></div>
              <div className={css['col-1__row-2--pag-2']}></div>
              <div className={css['col-1__row-2--pag-2']}></div>
              <div className={css['col-1__row-2--pag-3']}></div>
              <div className={css['col-1__row-2--pag-4']}></div>
              <div className={css['col-1__row-2--pag-5']}></div>
              <div className={css['col-1__row-2--pag-5']}></div>
              <div className={css['col-1__row-2--pag-5']}></div>
              <div className={css['col-1__row-2--pag-5']}></div>
              <div className={css['col-1__row-2--pag-5']}></div>
            </div>
          </div>
          <div className={css['col-1__row-3']}>
            <div className={css['col-1__row-3--text']}>
              60 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
            <div className={css['col-1__row-3--pag']}>
              <div className={css['col-1__row-3--pag-1']}></div>
              <div className={css['col-1__row-3--pag-2']}></div>
              <div className={css['col-1__row-3--pag-3']}></div>
              <div className={css['col-1__row-3--pag-3']}></div>
              <div className={css['col-1__row-3--pag-5']}></div>
              <div className={css['col-1__row-3--pag-5']}></div>
              <div className={css['col-1__row-3--pag-5']}></div>
              <div className={css['col-1__row-3--pag-5']}></div>
              <div className={css['col-1__row-3--pag-5']}></div>
              <div className={css['col-1__row-3--pag-5']}></div>
            </div>
          </div>
          <div className={css['col-1__row-4']}>
            <div className={css['col-1__row-4--text']}>
              65 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
            <div className={css['col-1__row-4--pag']}>
              <div className={css['col-1__row-4--pag-1']}></div>
              <div className={css['col-1__row-4--pag-2']}></div>
              <div className={css['col-1__row-4--pag-3']}></div>
              <div className={css['col-1__row-4--pag-4']}></div>
              <div className={css['col-1__row-4--pag-5']}></div>
              <div className={css['col-1__row-4--pag-5']}></div>
              <div className={css['col-1__row-4--pag-5']}></div>
              <div className={css['col-1__row-4--pag-5']}></div>
              <div className={css['col-1__row-4--pag-5']}></div>
              <div className={css['col-1__row-4--pag-5']}></div>
            </div>
          </div>
          <div className={css['col-1__row-5']}>
            <div className={css['col-1__row-5--text']}>
              70 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
            <div className={css['col-1__row-5--pag']}>
              <div className={css['col-1__row-5--pag-1']}></div>
              <div className={css['col-1__row-5--pag-2']}></div>
              <div className={css['col-1__row-5--pag-3']}></div>
              <div className={css['col-1__row-5--pag-5']}></div>
              <div className={css['col-1__row-5--pag-5']}></div>
              <div className={css['col-1__row-5--pag-5']}></div>
              <div className={css['col-1__row-5--pag-5']}></div>
              <div className={css['col-1__row-5--pag-5']}></div>
              <div className={css['col-1__row-5--pag-5']}></div>
              <div className={css['col-1__row-5--pag-5']}></div>
            </div>
          </div>
          <div className={css['col-1__row-6']}>
            <div className={css['col-1__row-6--text']}>
              75 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
            <div className={css['col-1__row-6--pag']}>
              <div className={css['col-1__row-6--pag-1']}></div>
              <div className={css['col-1__row-6--pag-2']}></div>
              <div className={css['col-1__row-6--pag-4']}></div>
              <div className={css['col-1__row-6--pag-5']}></div>
              <div className={css['col-1__row-6--pag-5']}></div>
              <div className={css['col-1__row-6--pag-5']}></div>
              <div className={css['col-1__row-6--pag-5']}></div>
              <div className={css['col-1__row-6--pag-5']}></div>
              <div className={css['col-1__row-6--pag-5']}></div>
              <div className={css['col-1__row-6--pag-5']}></div>
            </div>
          </div>
          <div className={css['col-1__row-7']}>
            <div className={css['col-1__row-7--text']}>
              80 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
            <div className={css['col-1__row-7--pag']}>
              <div className={css['col-1__row-7--pag-1']}></div>
              <div className={css['col-1__row-7--pag-2']}></div>
              <div className={css['col-1__row-7--pag-5']}></div>
              <div className={css['col-1__row-7--pag-5']}></div>
              <div className={css['col-1__row-7--pag-5']}></div>
              <div className={css['col-1__row-7--pag-5']}></div>
              <div className={css['col-1__row-7--pag-5']}></div>
              <div className={css['col-1__row-7--pag-5']}></div>
              <div className={css['col-1__row-7--pag-5']}></div>
              <div className={css['col-1__row-7--pag-5']}></div>
            </div>
          </div>
          <div className={css['col-1__row-8']}>
            <div className={css['col-1__row-8--text']}>
              85 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
            <div className={css['col-1__row-8--pag']}>
              <div className={css['col-1__row-8--pag-1']}></div>
              <div className={css['col-1__row-8--pag-6']}></div>
              <div className={css['col-1__row-8--pag-5']}></div>
              <div className={css['col-1__row-8--pag-5']}></div>
              <div className={css['col-1__row-8--pag-5']}></div>
              <div className={css['col-1__row-8--pag-5']}></div>
              <div className={css['col-1__row-8--pag-5']}></div>
              <div className={css['col-1__row-8--pag-5']}></div>
              <div className={css['col-1__row-8--pag-5']}></div>
              <div className={css['col-1__row-8--pag-5']}></div>
            </div>
          </div>
        </div>
        <div className={css['col-2']}>
          <div className={css['col-2__range']}>
            <div className={css['col-2__range--text']}>
              n-1
            </div>
            <div className={css['col-2__dumbbell']}>
              <div className={css['col-2__dumbbell--left']}></div>
              <div className={css['col-2__dumbbell--line']}></div>
              <div className={css['col-2__dumbbell--right']}></div>
            </div>
          </div>
          <div className={css['col-2__row-1']}>
            <div className={css['col-2__row-1--text']}>50 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span></div>
          </div>
          <div className={css['col-2__row-2']}>
            <div className={css['col-2__row-2--text']}>55 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span></div>
          </div>
          <div className={css['col-2__row-3']}>
            <div className={css['col-2__row-3--text']}>
              60 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-2__row-4']}>
            <div className={css['col-2__row-4--text']}>
              65 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-2__row-5']}>
            <div className={css['col-2__row-5--text']}>
              70 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-2__row-6']}>
            <div className={css['col-2__row-6--text']}>
              75 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-2__row-7']}>
            <div className={css['col-2__row-7--text']}>
              80 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-2__row-8']}>
            <div className={css['col-2__row-8--text']}>
              13 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
        </div>
        <div className={css['col-3']}>
          <div className={css['col-3__range']}>
            <div className={css['col-3__range--text']}>
              n-2
            </div>
            <div className={css['col-3__dumbbell']}>
              <div className={css['col-3__dumbbell--left']}></div>
              <div className={css['col-3__dumbbell--line']}></div>
              <div className={css['col-3__dumbbell--right']}></div>
            </div>
          </div>
          <div className={css['col-3__row-1']}>
            <div className={css['col-3__row-1--text']}>25 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span></div>
          </div>
          <div className={css['col-3__row-2']}>
            <div className={css['col-3__row-2--text']}>25 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span></div>
          </div>
          <div className={css['col-3__row-3']}>
            <div className={css['col-3__row-3--text']}>
              24 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-3__row-4']}>
            <div className={css['col-3__row-4--text']}>
              23 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-3__row-5']}>
            <div className={css['col-3__row-5--text']}>
              21 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-3__row-6']}>
            <div className={css['col-3__row-6--text']}>
              19 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-3__row-7']}>
            <div className={css['col-3__row-7--text']}>
              16 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-3__row-8']}>
            <div className={css['col-3__row-8--text']}>
              1 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
        </div>
        <div className={css['col-4']}>
          <div className={css['col-4__range']}>
            <div className={css['col-4__range--text']}>
              n-3
            </div>
            <div className={css['col-4__dumbbell']}>
              <div className={css['col-4__dumbbell--left']}></div>
              <div className={css['col-4__dumbbell--line']}></div>
              <div className={css['col-4__dumbbell--right']}></div>
            </div>
          </div>
          <div className={css['col-4__row-1']}>
            <div className={css['col-4__row-1--text']}>
              13 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-4__row-2']}>
            <div className={css['col-4__row-2--text']}>
              11 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-4__row-3']}>
            <div className={css['col-4__row-3--text']}>
              10 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-4__row-4']}>
            <div className={css['col-4__row-4--text']}>
              8 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-4__row-5']}>
            <div className={css['col-4__row-5--text']}>
              6 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-4__row-6']}>
            <div className={css['col-4__row-6--text']}>
              5 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-4__row-7']}>
            <div className={css['col-4__row-7--text']}>
              3 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-4__row-8']}>
            <div className={css['col-4__row-8--text']}>
              <span style={{ color: '#c2c7cc', fontWeight: '400' }}>— %</span>
            </div>
          </div>
        </div>
        <div className={css['col-5']}>
          <div className={css['col-5__range']}>
            <div className={css['col-5__range--text']}>
              n-4
            </div>
            <div className={css['col-5__dumbbell']}>
              <div className={css['col-5__dumbbell--left']}></div>
              <div className={css['col-5__dumbbell--line']}></div>
              <div className={css['col-5__dumbbell--right']}></div>
            </div>
          </div>
          <div className={css['col-5__row-1']}>
            <div className={css['col-5__row-1--text']}>
              6 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-5__row-2']}>
            <div className={css['col-5__row-2--text']}>
              5 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-5__row-3']}>
            <div className={css['col-5__row-3--text']}>
              4 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-5__row-4']}>
            <div className={css['col-5__row-4--text']}>
              3 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-5__row-5']}>
            <div className={css['col-5__row-5--text']}>
              2 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-5__row-6']}>
            <div className={css['col-5__row-6--text']}>
              1<span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-5__row-7']}>
            <div className={css['col-5__row-7--text']}>
              1<span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-5__row-8']}>
            <div className={css['col-5__row-8--text']}>
              <span style={{ color: '#c2c7cc', fontWeight: '400' }}>— %</span>
            </div>
          </div>
        </div>
        <div className={css['col-6']}>
          <div className={css['col-6__range']}>
            <div className={css['col-6__range--text']}>
              n-1
            </div>
            <div className={css['col-6__dumbbell']}>
              <div className={css['col-6__dumbbell--left']}></div>
              <div className={css['col-6__dumbbell--line']}></div>
              <div className={css['col-6__dumbbell--right']}></div>
            </div>
          </div>
          <div className={css['col-6__row-1']}>
            <div className={css['col-6__row-1--text']}>
              3 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-6__row-2']}>
            <div className={css['col-6__row-2--text']}>
              2 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-6__row-3']}>
            <div className={css['col-6__row-3--text']}>
              1 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-6__row-4']}>
            <div className={css['col-6__row-4--text']}>
              1 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-6__row-5']}>
            <div className={css['col-6__row-5--text']}>
              1 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-6__row-6']}>
            <div className={css['col-6__row-6--text']}>
              <span style={{ color: '#c2c7cc', fontWeight: '400' }}>— %</span>
            </div>
          </div>
          <div className={css['col-6__row-7']}>
            <div className={css['col-6__row-7--text']}>
              <span style={{ color: '#c2c7cc', fontWeight: '400' }}>— %</span>
            </div>
          </div>
          <div className={css['col-6__row-8']}>
            <div className={css['col-6__row-8--text']}>
              <span style={{ color: '#c2c7cc', fontWeight: '400' }}>— %</span>
            </div>
          </div>
        </div>
        <div className={css['col-7']}>
          <div className={css['col-7__title']}>
            n-кратна віддаль між <span style={{ color: '#62C960' }}>рослинами</span> в ряду
          </div>
          <div className={css['col-7__range']}>
            <div className={css['col-7__range--text']}>
              n-1
            </div>
            <div className={css['col-7__dumbbell']}>
              <div className={css['col-7__dumbbell--left']}></div>
              <div className={css['col-7__dumbbell--line']}></div>
              <div className={css['col-7__dumbbell--right']}></div>
            </div>
          </div>
          <div className={css['col-7__row-1']}>
            <div className={css['col-7__row-1--text']}>
              1 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-7__row-2']}>
            <div className={css['col-7__row-2--text']}>
              2 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-7__row-3']}>
            <div className={css['col-7__row-3--text']}>
              1 <span style={{ color: '#c2c7cc', fontWeight: '400' }}>%</span>
            </div>
          </div>
          <div className={css['col-7__row-4']}>
            <div className={css['col-7__row-4--text']}>
              <span style={{ color: '#c2c7cc', fontWeight: '400' }}>— %</span>
            </div>
          </div>
          <div className={css['col-7__row-5']}>
            <div className={css['col-7__row-5--text']}>
              <span style={{ color: '#c2c7cc', fontWeight: '400' }}>— %</span>
            </div>
          </div>
          <div className={css['col-7__row-6']}>
            <div className={css['col-7__row-6--text']}>
              <span style={{ color: '#c2c7cc', fontWeight: '400' }}>— %</span>
            </div>
          </div>
          <div className={css['col-7__row-7']}>
            <div className={css['col-7__row-7--text']}>
              <span style={{ color: '#c2c7cc', fontWeight: '400' }}>— %</span>
            </div>
          </div>
          <div className={css['col-7__row-8']}>
            <div className={css['col-7__row-8--text']}>
              <span style={{ color: '#c2c7cc', fontWeight: '400' }}>— %</span>
            </div>
          </div>
        </div>



      </div>

    </>
  )
}