// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.technologyBeet_tech__0p6Sa {
  padding: 112px 0 112px 0;
}
.technologyBeet_tech__0p6Sa h2, .technologyBeet_tech__0p6Sa img {
  padding-bottom: 24px;
}
.technologyBeet_tech__0p6Sa img {
  border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./src/views/technologyBeet/technologyBeet.module.scss"],"names":[],"mappings":"AAGA;EACE,wBAAA;AAFF;AAIE;EACE,oBAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ","sourcesContent":["@import \"./../../styles/mixins.scss\";\n@import \"./../../styles/variables.scss\";\n\n.tech {\n  padding: 112px 0 112px 0;\n\n  h2, img {\n    padding-bottom: 24px;\n  }\n\n  img {\n    border-radius: 2px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tech": `technologyBeet_tech__0p6Sa`
};
export default ___CSS_LOADER_EXPORT___;
