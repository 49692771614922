// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LectureDetails_wrapperDetails__y7Z-y {
  padding-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/views/LectureDetails/LectureDetails.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ","sourcesContent":[".wrapperDetails {\n    padding-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperDetails": `LectureDetails_wrapperDetails__y7Z-y`
};
export default ___CSS_LOADER_EXPORT___;
