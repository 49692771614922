import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getPageItem } from "../../axios/pages";
import DynamicInfos from "../../components/DynamicInfos/DynamicInfos";
import styles from "./Gasprod.module.scss";
import { EnergyTitle } from "../../components/energy/EnergyTitle";

export default function Gasprod() {
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState([]);
  const startIndex = 1; // start from 0

  useEffect(()=>{
      let active = false;
      getPageItem(setLoading, 4).then(response=>{
          setPageData(response.pageInfos);
  
      }).catch(err=>{
        console.log(err);
      })

      return () => {
          active = true;
      }; 
  }, []);
  
  return (
    <div className={styles.gasprodContainer}>
      <div className={styles.gasprodContainer__innerContent}>
        <img src="/media/images/energy/title-arrow.svg" alt="export" />
        <div className={styles.gasprodContainer__innerContent__section1}>
          <div className={styles.div__h1}>
            <h1>01</h1>
          </div>
          <p className={styles.p4}>Договір</p>
        </div>
        <div className={styles.gasprodContainer__innerContent__section2}>
          <div className={styles.div__svg__arrow}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 20L11.2929 20.7071L12 21.4142L12.7071 20.7071L12 20ZM13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5L13 5ZM5.29289 14.7071L11.2929 20.7071L12.7071 19.2929L6.70711 13.2929L5.29289 14.7071ZM12.7071 20.7071L18.7071 14.7071L17.2929 13.2929L11.2929 19.2929L12.7071 20.7071ZM13 20L13 5L11 5L11 20L13 20Z"
                fill="#33363F"
              />
            </svg>
          </div>

          <p>
            <Link to="#">
              Для отримання шаблону договору на постачання газу зверніться за
              контактами вказаними внизу сторінки
            </Link>
          </p>
        </div>
      </div>
      {/* ===========================================2================================================================ */}
      <DynamicInfos pageData={pageData} startIndex = {startIndex}/>

      <div className={styles.gasprodContainer__innerContent}>
        
      <EnergyTitle index={pageData.length + startIndex + 1} text={'Контакти'} />
        <div className={styles.gasprodContainer__innerContent__section3}>
          <p>
            <strong>Менеджер:</strong> Ващишин Аліна Юріївна
          </p>
          <p>
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.6459 10.7662C17.2153 10.7662 16.8748 10.4157 16.8748 9.99501C16.8748 9.62444 16.5042 8.85325 15.8833 8.18222C15.2724 7.53121 14.6014 7.15063 14.0406 7.15063C13.61 7.15063 13.2695 6.80009 13.2695 6.37944C13.2695 5.95879 13.62 5.60825 14.0406 5.60825C15.0421 5.60825 16.0936 6.14908 17.015 7.12058C17.8762 8.03198 18.427 9.16373 18.427 9.98499C18.427 10.4157 18.0765 10.7662 17.6459 10.7662Z"
                  fill="#45494D"
                />
                <path
                  d="M21.261 10.7666C20.8303 10.7666 20.4898 10.416 20.4898 9.9954C20.4898 6.43992 17.5956 3.55548 14.0504 3.55548C13.6197 3.55548 13.2792 3.20494 13.2792 2.78429C13.2792 2.36364 13.6197 2.00309 14.0404 2.00309C18.4468 2.00309 22.0321 5.58861 22.0321 9.9954C22.0321 10.416 21.6816 10.7666 21.261 10.7666Z"
                  fill="#45494D"
                />
                <path
                  d="M11.0662 14.9731L9.21352 16.8259C8.82294 17.2165 8.20203 17.2165 7.80144 16.8359C7.69128 16.7258 7.58112 16.6256 7.47096 16.5154C6.43944 15.4738 5.50808 14.3822 4.67686 13.2404C3.85565 12.0986 3.19468 10.9569 2.71397 9.82514C2.24328 8.68338 2.00293 7.5917 2.00293 6.55009C2.00293 5.86904 2.12311 5.21804 2.36346 4.61711C2.60381 4.00617 2.98437 3.44531 3.51515 2.94454C4.15609 2.31356 4.85712 2.00309 5.59821 2.00309C5.87862 2.00309 6.15903 2.06318 6.4094 2.18336C6.66978 2.30355 6.90012 2.48383 7.08039 2.74423L9.4038 6.01927C9.58406 6.26966 9.71425 6.50001 9.80439 6.72035C9.89452 6.93068 9.94459 7.141 9.94459 7.3313C9.94459 7.57167 9.87449 7.81204 9.73428 8.04239C9.60409 8.27275 9.41381 8.51312 9.17346 8.75349L8.41234 9.54471C8.30218 9.65488 8.25211 9.78508 8.25211 9.94532C8.25211 10.0254 8.26212 10.0956 8.28215 10.1757C8.31219 10.2558 8.34224 10.3159 8.36227 10.376C8.54253 10.7065 8.85299 11.1372 9.29364 11.658C9.7443 12.1788 10.225 12.7096 10.7458 13.2404C10.8459 13.3406 10.9561 13.4407 11.0562 13.5409C11.4568 13.9315 11.4668 14.5725 11.0662 14.9731Z"
                  fill="#45494D"
                />
                <path
                  d="M22.0019 18.3583C22.0019 18.6388 21.9518 18.9292 21.8517 19.2096C21.8216 19.2898 21.7916 19.3699 21.7515 19.45C21.5813 19.8106 21.361 20.1511 21.0705 20.4716C20.5798 21.0124 20.039 21.403 19.4281 21.6534C19.4181 21.6534 19.4081 21.6634 19.3981 21.6634C18.8072 21.9038 18.1663 22.034 17.4753 22.034C16.4538 22.034 15.3621 21.7936 14.2105 21.3029C13.0588 20.8121 11.9071 20.1511 10.7654 19.3198C10.3748 19.0294 9.98425 18.7389 9.61371 18.4284L12.8885 15.1534C13.1689 15.3637 13.4193 15.524 13.6296 15.6341C13.6797 15.6542 13.7398 15.6842 13.8099 15.7143C13.89 15.7443 13.9701 15.7543 14.0602 15.7543C14.2305 15.7543 14.3607 15.6942 14.4708 15.5841L15.232 14.8329C15.4823 14.5825 15.7227 14.3922 15.953 14.272C16.1834 14.1318 16.4137 14.0617 16.6641 14.0617C16.8543 14.0617 17.0546 14.1018 17.275 14.1919C17.4953 14.282 17.7256 14.4122 17.976 14.5825L21.2909 16.9361C21.5512 17.1164 21.7315 17.3267 21.8417 17.5771C21.9418 17.8275 22.0019 18.0779 22.0019 18.3583Z"
                  fill="#45494D"
                />
              </svg>
            </span>
            <Link  to={'tel:+380504366391'}>+38 050 436 63 91</Link>
          </p>
          <p>
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.025 3.5054H7.01028C4.00587 3.5054 2.00293 5.00772 2.00293 8.51312V15.5239C2.00293 19.0293 4.00587 20.5316 7.01028 20.5316H17.025C20.0294 20.5316 22.0323 19.0293 22.0323 15.5239V8.51312C22.0323 5.00772 20.0294 3.5054 17.025 3.5054ZM17.4957 9.6048L14.3611 12.1087C13.7001 12.6395 12.8589 12.8999 12.0176 12.8999C11.1764 12.8999 10.3251 12.6395 9.67419 12.1087L6.53959 9.6048C6.21912 9.3444 6.16905 8.86366 6.41941 8.54316C6.6798 8.22267 7.15049 8.16258 7.47096 8.42298L10.6056 10.9268C11.3667 11.5378 12.6586 11.5378 13.4197 10.9268L16.5543 8.42298C16.8748 8.16258 17.3555 8.21265 17.6058 8.54316C17.8662 8.86366 17.8161 9.3444 17.4957 9.6048Z"
                  fill="#45494D"
                />
              </svg>
            </span>
            <Link to={'mailto:vysovenj.a@radsugar.com.ua'}>vysovenj.a@radsugar.com.ua</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
