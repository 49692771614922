// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup_popup__19RCv {
  position: absolute;
  min-width: 920px;
  width: 100%;
  top: 20%;
  left: -150%;
  border-radius: 2px;
  background: #C2C7CC;
  margin-top: 70px;
}
.popup_popup__19RCv p {
  margin-bottom: 20px;
  color: black;
}
.popup_popup__19RCv p span {
  font-weight: 700;
}
.popup_popup__19RCv li span {
  font-weight: 700;
  color: black;
}
.popup_popup__19RCv li, .popup_popup__19RCv div {
  color: black;
}
.popup_popup__19RCv a:hover {
  color: #fff;
}
.popup_popup__inner__yT0rH {
  padding: 32px 64px;
}
.popup_popup__head__QrKaf {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 8px;
}
.popup_popup__19RCv .popup_room__title__ntbOe {
  border: none !important;
}

.popup_popup-main__item__kExDP {
  padding-bottom: 24px;
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 8px;
}
.popup_popup-main__item__kExDP:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

@media (max-width: 560px) {
  .popup_popup__19RCv {
    min-width: 100vw;
    width: 100%;
    top: 65%;
    left: -20px;
    margin-top: 50px;
  }
  .popup_popup__inner__yT0rH {
    padding: 15px 10px;
  }
  .popup_popup__head__QrKaf {
    flex-wrap: wrap;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LogRegister/popup.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,QAAA;EACA,WAAA;EACA,kBAAA;EAEA,mBAAA;EACA,gBAAA;AAAJ;AAEI;EACI,mBAAA;EACA,YAAA;AAAR;AACQ;EACI,gBAAA;AACZ;AAEI;EACI,gBAAA;EACA,YAAA;AAAR;AAEI;EACI,YAAA;AAAR;AAGI;EACI,WAAA;AADR;AAII;EACI,kBAAA;AAFR;AAII;EACI,aAAA;EACA,8BAAA;EACA,oBAAA;EACA,gCAAA;EACA,kBAAA;AAFR;AAKI;EACI,uBAAA;AAHR;;AAOA;EACI,oBAAA;EACA,gCAAA;EACA,kBAAA;AAJJ;AAKI;EACI,mBAAA;EACA,iBAAA;AAHR;;AAOA;EACI;IACI,gBAAA;IACA,WAAA;IACA,QAAA;IACA,WAAA;IACA,gBAAA;EAJN;EAMM;IACI,kBAAA;EAJV;EAOM;IACI,eAAA;EALV;AACF","sourcesContent":[".popup {\n    position: absolute;\n    min-width: 920px;\n    width: 100%;\n    top: 20%;\n    left: -150%;\n    border-radius: 2px;\n    // background: #5d6166;\n    background: #C2C7CC;\n    margin-top: 70px;\n\n    p {\n        margin-bottom: 20px;\n        color: black;\n        span {\n            font-weight: 700;\n        }\n    }\n    li span {\n        font-weight: 700;\n        color: black;\n    }\n    li, div{\n        color: black;\n    }\n\n    a:hover{\n        color: #fff;\n    }\n\n    &__inner {\n        padding: 32px 64px;\n    }\n    &__head {\n        display: flex;\n        justify-content: space-between;\n        padding-bottom: 24px;\n        border-bottom: 1px solid #FFFFFF;\n        margin-bottom: 8px;\n    }\n\n    .room__title {\n        border: none !important;\n    }\n}\n\n.popup-main__item {\n    padding-bottom: 24px;\n    border-bottom: 1px solid #FFFFFF;\n    margin-bottom: 8px;\n    &:last-child {\n        border-bottom: none;\n        padding-bottom: 0;\n    }\n}\n\n@media (max-width: 560px) { \n    .popup {\n        min-width: 100vw;\n        width: 100%;\n        top: 65%;\n        left: -20px;\n        margin-top: 50px;\n\n        &__inner {\n            padding: 15px 10px;\n        }\n\n        &__head {\n            flex-wrap: wrap;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `popup_popup__19RCv`,
	"popup__inner": `popup_popup__inner__yT0rH`,
	"popup__head": `popup_popup__head__QrKaf`,
	"room__title": `popup_room__title__ntbOe`,
	"popup-main__item": `popup_popup-main__item__kExDP`
};
export default ___CSS_LOADER_EXPORT___;
