import React from 'react'
import css from "./history.module.scss";
import HistoryElement from '../../components/History/HistoryElement/HistoryElement';
import { useTranslation } from "react-multi-lang";

const History = () => {
  const translate = useTranslation();

  const period = {
    before: [1867, 1873],
    after: [1952, 2023],
    show: [
      { num: 1870, title: translate('history.1870.title'), content: translate('history.1870.content'), type: 'main' },
      { num: 1954, title: translate('history.1954.title'), content: translate('history.1954.content'), type: 'main' },
      { num: 1955, title: translate('history.1955.title'), content: translate('history.1955.content'), type: 'main' },
      { num: 1958, title: translate('history.1958h.title'), content: translate('history.1958h.content'), type: 'main' },
      { num: 1958, title: translate('history.1958g.title'), content: translate('history.1958g.content'), type: 'main', component: true },
      { num: 1959, content: translate('history.1959.content'), type: 'low' },
      { num: 1963, title: translate('history.1963.title'), content: translate('history.1963.content'), type: 'main' },
      { num: 1968, content: translate('history.1968.content'), type: 'low' },
      { num: 1969, content: translate('history.1969.content'), type: 'low' },
      { num: 1971, content: translate('history.1971.content'), type: 'low' },
      { num: 1974, title: translate('history.1974.title'), content: translate('history.1974.content'), type: 'main', component: true },
      { num: 1975, content: translate('history.1975.content'), type: 'low' },
      { num: 1976, content: translate('history.1976.content'), type: 'low' },
      { num: 1977, title: translate('history.1977.title'), content: translate('history.1977.content'), type: 'main' },
      { num: 1980, content: translate('history.1980.content'), type: 'low' },
      { num: 1982, title: translate('history.1982.title'), content: translate('history.1982.content'), type: 'main' },
      { num: 1996, content: translate('history.1996.content'), type: 'low' },
      { num: 2003, content: translate('history.2003.content'), type: 'low' },
      { num: 2007, content: translate('history.2007.content'), type: 'low' },
      { num: 2010, title: translate('history.2010.title'), content: translate('history.2010.content'), type: 'main' },
      { num: 2011, content: translate('history.2011.content'), type: 'low' },
      { num: 2013, content: translate('history.2013.content'), type: 'low' },
      { num: 2014, content: translate('history.2014.content'), type: 'low' },
      { num: 2023, title: translate('history.2023.title'), content: translate('history.2023.content'), type: 'main' },
    ],
  }

  const beforeArr = [];
  const afterArr = [];

  for (let i = period.before[0] + 1; i <= period.before[1]; i++) {
    const show = period.show.filter(item => {
      return item.num === i;
    })
    if (show.length) {
      beforeArr.push({ num: show[0].num, type: show[0].type, component: show[0].component, content: show[0].content, title: show[0].title || '' })
    }
    else {
      beforeArr.push({ num: i, type: 'default' })
    }
  }

  // ============================

  for (let i = period.after[0]; i <= period.after[1]; i++) {

    // повертаємо масив для рендру який проходить умову 
    const show = period.show.filter(item => {
      return item.num === i;
    })

    if (show.length === 1) {
      afterArr.push({ num: show[0].num, type: show[0].type, component: show[0].component, content: show[0].content, title: show[0].title || '' })
    }

    else if (show.length > 1) {
      afterArr.push({ num: show[0].num, type: show[0].type, component: show[0].component, content: show[0].content, title: show[0].title || '' })
      afterArr.push({ num: show[1].num, type: show[1].type, component: show[1].component, content: show[1].content, title: show[1].title || '' })
    }

    else {
      afterArr.push({ num: i, type: 'default' })
    }
  }


  return (
    <section className={css["history"]}>
      <div className="container">
        <div className={css["openDates"]}>
          <img src="/media/images/historyOpens.png" alt="img" />
        </div>
        <div className={css["description"]}>
          ТзОВ «Радехівський цукор» - це сучасне та модернізоване підприємство, що спеціалізується на переробці цукрового буряка, виробництві цукру, жому та меляси. Підприємство є одним найбільших виробників цукру в  Україні.
          З 2010 року ТзОВ «Радехівський цукор» входить  до міжнародної групи компаній Pfeifer&Langen.
        </div>
        <div className={css["division"]}>
          <div className={css["division-card"]}>
            <div className={css["title"]}>
              <div className={css["title-nums"]}>
                <div className={css["title-num-1"]}>01<span>/ </span></div>
                <div className={css["title-num-2"]}> 06</div>
              </div>
              <div className={css["title-text"]}>Радехівський
                <br />
                підрозділ</div>
            </div>
            <div className={css["content-1"]}>
              Будівництво Радехівського цукрового заводу розпочалося в червні  1974 року на території с. Павлів Радехівського району Львівської області  та ривало 4 роки.
            </div>
            <div className={css["content-2"]}>
              <div className={css["content-title"]}>Виробнича потужність заводу — 9 000 <span>тонн/добу</span> переробки цукрового буряка. </div>
              <div className={css["content-range"]}>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
              </div>
              <div className={css["content-footer"]}>Завод виробляє
                цукор, мелясу та жом сухий гранульований.</div>
            </div>
          </div>
          <div className={css["division-card"]}>
            <div className={css["title"]}>
              <div className={css["title-nums"]}>
                <div className={css["title-num-1"]}>02<span>/ </span></div>
                <div className={css["title-num-2"]}> 06</div>
              </div>
              <div className={css["title-text"]}>Чортківський
                <br />
                підрозділ </div>
            </div>
            <div className={css["content-1"]}>
              Завод був введений в експлуатацію в листопаді 1977 року.
              В травні 2013 року ТзОВ «Радехівський цукор» розширило межі своєї діяльності на територію Тернопільської області шляхом придбання виробничих потужностей ТзОВ «Чортківський цукровий завод».
            </div>
            <div className={css["content-2"]}>
              <div className={css["content-title"]}>Виробнича потужність заводу — 7 000 <span>тонн/добу</span> переробки цукрового буряка. </div>
              <div className={css["content-range"]}>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part"]}></div>
                <div className={css["content-range-part"]}></div>
              </div>
              <div className={css["content-footer"]}>Завод виробляє
                цукор, мелясу та жом сухий гранульований.</div>
            </div>
          </div>
          <div className={css["division-card"]}>
            <div className={css["title"]}>
              <div className={css["title-nums"]}>
                <div className={css["title-num-1"]}>03<span>/ </span></div>
                <div className={css["title-num-2"]}> 06</div>
              </div>
              <div className={css["title-text"]}>Хоростківський
                <br />
                підрозділ</div>
            </div>
            <div className={css["content-1"]}>
              Переробка сировини розпочалася в 1958 році.
            </div>
            <div className={css["content-2"]}>
              <div className={css["content-title"]}>Виробнича потужність заводу — 7 000
                <span>тонн/добу</span> переробки цукрового буряка. </div>
              <div className={css["content-range"]}>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part"]}></div>
                <div className={css["content-range-part"]}></div>
              </div>
              <div className={css["content-footer"]}>Завод виробляє
                цукор, мелясу та жом сухий гранульований.</div>
            </div>
          </div>
          <div className={css["division-card"]}>
            <div className={css["title"]}>
              <div className={css["title-nums"]}>
                <div className={css["title-num-1"]}>04<span>/ </span></div>
                <div className={css["title-num-2"]}> 06</div>
              </div>
              <div className={css["title-text"]}>Збаразький
                <br />
                підрозділ</div>
            </div>
            <div className={css["content-1"]}>
              Будівництво Збаразького цукрового заводу було розпочате в листопаді і введено в експлуатацію в 1969 році.
            </div>
            <div className={css["content-2"]}>
              <div className={css["content-title"]}>Виробнича потужність заводу — 3 700 <span>тонн/добу</span> переробки цукрового буряка. </div>
              <div className={css["content-range"]}>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-75"]}></div>
                <div className={css["content-range-part"]}></div>
                <div className={css["content-range-part"]}></div>
                <div className={css["content-range-part"]}></div>
                <div className={css["content-range-part"]}></div>
                <div className={css["content-range-part"]}></div>
              </div>
              <div className={css["content-footer"]}>Завод виробляє
                цукор та мелясу.
              </div>
            </div>
          </div>
          <div className={css["division-card"]}>
            <div className={css["title"]}>
              <div className={css["title-nums"]}>
                <div className={css["title-num-1"]}>05<span>/ </span></div>
                <div className={css["title-num-2"]}> 06</div>
              </div>
              <div className={css["title-text"]}>Козівський
                <br />
                підрозділ</div>
            </div>
            <div className={css["content-1"]}>
              Будівництво цукрового заводу  розпочато в 1954 році, а першу продукцію завод випустив 29 грудня 1959 року.
            </div>
            <div className={css["content-2"]}>
              <div className={css["content-title"]}>Виробнича потужність заводу — 3 700 <span>тонн/добу</span> переробки цукрового буряка. </div>
              <div className={css["content-range"]}>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-75"]}></div>
                <div className={css["content-range-part"]}></div>
                <div className={css["content-range-part"]}></div>
                <div className={css["content-range-part"]}></div>
                <div className={css["content-range-part"]}></div>
                <div className={css["content-range-part"]}></div>
              </div>
              <div className={css["content-footer"]}>Завод виробляє
                цукор, мелясу та жом сухий гранульований.</div>
            </div>
          </div>
          <div className={css["division-card"]}>
            <div className={css["title"]}>
              <div className={css["title-nums"]}>
                <div className={css["title-num-1"]}>06<span>/ </span></div>
                <div className={css["title-num-2"]}> 06</div>
              </div>
              <div className={css["title-text"]}>Гнідавський
                <br />
                підрозділ</div>
            </div>
            <div className={css["content-1"]}>
              У 1958 році закінчено комплексне випробування устаткування першої черги заводу на виробничу потужність 1,5 тис. т переробки буряків на добу. У грудні 1958 року було одержано перший цукор
            </div>
            <div className={css["content-2"]}>
              <div className={css["content-title"]}>Виробнича потужність заводу — 6 000 <span>тонн/добу</span> переробки цукрового буряка. </div>
              <div className={css["content-range"]}>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part-active"]}></div>
                <div className={css["content-range-part"]}></div>
                <div className={css["content-range-part"]}></div>
                <div className={css["content-range-part"]}></div>
              </div>
              <div className={css["content-footer"]}>Завод виробляє
                цукор, мелясу та жом сухий гранульований.</div>
            </div>
          </div>
        </div>

        <div className={css["period"]}>
          <svg xmlns="http://www.w3.org/2000/svg" width="2" height="82" viewBox="0 0 2 82" fill="none">
            <path d="M1 1L0.999994 82" stroke="url(#paint0_linear_460_8260)" strokeWidth="2" strokeLinecap="round" />
            <defs>
              <linearGradient id="paint0_linear_460_8260" x1="0.99999" y1="82.074" x2="1" y2="1" gradientUnits="userSpaceOnUse">
                <stop stopColor="#B8C2CC" />
                <stop offset="1" stopColor="#B8C2CC" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
          <div className={css["period__number"]}>
            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="12" viewBox="0 0 2 12" fill="none">
              <path d="M1 1L1.00019 00012" stroke="#A1AAB3" strokeWidth="2" strokeLinecap="round" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="2" viewBox="0 0 7 2" fill="none">
              <path d="M1 1H6" stroke="#C2C7CC" strokeLinecap="round" />
            </svg>
            {period.before[0]}
          </div>
        </div>
        {beforeArr.map((period, index) => {
          return <HistoryElement key={index} data={period} />
        })}
        <div className={css["period"]}>
          <svg xmlns="http://www.w3.org/2000/svg" width="2" height="42" viewBox="0 0 2 42" fill="none">
            <path d="M1 1L1.00019 00042" stroke="#A1AAB3" strokeWidth="2" strokeLinecap="round" />
          </svg>
          <div className={css["period__number"]}>
            <svg xmlns="http://www.w3.org/2000/svg" width="2" height="12" viewBox="0 0 2 12" fill="none">
              <path d="M1 1L1.00019 00012" stroke="#A1AAB3" strokeWidth="2" strokeLinecap="round" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="2" viewBox="0 0 7 2" fill="none">
              <path d="M1 1H6" stroke="#C2C7CC" strokeLinecap="round" />
            </svg>
            <p style={{ position: 'relative', bottom: '5px', left: '5px', color: '#C2C7CC' }}>...</p>
          </div>
        </div>
        {afterArr.map((period, index) => {
          return <HistoryElement key={index} data={period} />
        })}
        <div className={css["period"]}>
          <svg xmlns="http://www.w3.org/2000/svg" width="2" height="82" viewBox="0 0 2 82" fill="none">
            <path d="M1 137L1.00001 1" stroke="url(#paint0_linear_453_7661)" strokeWidth="2" strokeLinecap="round" />
            <defs>
              <linearGradient id="paint0_linear_453_7661" x1="1.00001" y1="7.92592" x2="0.999997" y2="82" gradientUnits="userSpaceOnUse">
                <stop stopColor="#B8C2CC" />
                <stop offset="1" stopColor="#B8C2CC" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </section>
  )
}

export default History