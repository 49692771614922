const dataNews = [
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2019",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2018",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2023",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Міжзаводська конференція інженерно-технічних працівників",
    description:
      "8 червня 2023 року відбулася міжзаводська конференція щодо обміну досвідом, на якій зібралися інженерно-технічні працівники ТзОВ «Радехівський цукор».",
    date: "14.06.2022",
    imgURL: "/media/images/hands.png",
  },
  {
    title: "Святкуємо день вишиванки",
    description:
      "Сьогодні наша країна та всі ми — українці відзначаємо день Вишиванки.Це вбрання є генетичним кодом української нації.",
    date: "08.06.2021",
    imgURL: "/media/images/strawberry.png",
  },
  {
    title: "Екологічний аудит. Прагнення до кращого...",
    description:
      "Компанія ТзОВ «Радехівський цукор» у своїй діяльності велику увагу приділяє екологічним питанням і всіляко сприяє дотриманню норм у сфері захисту довкілля та екології.",
    date: "23.06.2020",
    imgURL: "/media/images/hands.png",
  },

  
 


];

const allDataNews = {
  dataNews,
};

export default allDataNews;
