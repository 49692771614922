// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.historyelement_period__EiQzY {
  margin-bottom: 4px;
}
.historyelement_period__number__HMobZ {
  display: inline-flex;
  align-items: center;
  position: relative;
  bottom: -10px;
  left: -2px;
}
.historyelement_period__number__HMobZ p.historyelement_default__DfYFf {
  color: #C2C7CC;
  font-size: 14px;
  line-height: 24px;
}
.historyelement_period__number__HMobZ p.historyelement_low__GHKB1 {
  color: #292E33;
  font-size: 15px;
  line-height: 24px;
}
.historyelement_period__number__HMobZ p.historyelement_main__AB7nb {
  color: #292E33;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.historyelement_period__number__HMobZ.historyelement_low__GHKB1 {
  left: -6px;
  gap: 3px;
}
.historyelement_period__number__HMobZ.historyelement_main__AB7nb {
  left: -8px;
  gap: 4px;
}
.historyelement_period__content__2PUEw {
  position: absolute;
  left: 102%;
  max-width: 395px;
  width: 100%;
}
.historyelement_period__content__2PUEw svg {
  margin-left: 10px;
  cursor: pointer;
}
.historyelement_period__content__2PUEw svg path {
  transition: all 0.3s;
}
.historyelement_period__content__2PUEw svg:hover path {
  fill: #292E33;
}

.historyelement_period__content__2PUEw h3 {
  color: #292E33;
}`, "",{"version":3,"sources":["webpack://./src/components/History/HistoryElement/historyelement.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,oBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,UAAA;AACR;AACQ;EACI,cAAA;EACA,eAAA;EACA,iBAAA;AACZ;AACQ;EACI,cAAA;EACA,eAAA;EACA,iBAAA;AACZ;AACQ;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACZ;AAGI;EACI,UAAA;EACA,QAAA;AADR;AAGI;EACI,UAAA;EACA,QAAA;AADR;AAII;EACI,kBAAA;EACA,UAAA;EACA,gBAAA;EACA,WAAA;AAFR;AAIQ;EACI,iBAAA;EACA,eAAA;AAFZ;AAIY;EACI,oBAAA;AAFhB;AAMgB;EACI,aAAA;AAJpB;;AAYI;EACI,cAAA;AATR","sourcesContent":[".period {\n    margin-bottom: 4px;\n\n    &__number {\n        display: inline-flex;\n        align-items: center;\n        position: relative;\n        bottom: -10px;\n        left: -2px;\n\n        p.default {\n            color: #C2C7CC;\n            font-size: 14px;\n            line-height: 24px;\n        }\n        p.low { \n            color: #292E33;\n            font-size: 15px;\n            line-height: 24px;\n        }\n        p.main { \n            color: #292E33;\n            font-size: 16px;\n            font-weight: 700;\n            line-height: 24px;\n        }\n    }\n\n    &__number.low {\n        left: -6px;\n        gap: 3px;\n    }\n    &__number.main {\n        left: -8px;\n        gap: 4px;\n    }\n\n    &__content{\n        position: absolute;\n        left: 102%;\n        max-width: 395px;\n        width: 100%;\n\n        svg {\n            margin-left: 10px;\n            cursor: pointer;\n\n            path {\n                transition: all 0.3s;\n            }\n            \n            &:hover {\n                path {\n                    fill: #292E33;\n                }\n            }\n        }\n    }\n}\n\n.period__content {\n    h3 {\n        color: #292E33;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"period": `historyelement_period__EiQzY`,
	"period__number": `historyelement_period__number__HMobZ`,
	"default": `historyelement_default__DfYFf`,
	"low": `historyelement_low__GHKB1`,
	"main": `historyelement_main__AB7nb`,
	"period__content": `historyelement_period__content__2PUEw`
};
export default ___CSS_LOADER_EXPORT___;
