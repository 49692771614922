// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SocialActivity_socialActivityHead__bxZRO .SocialActivity_wrapper__eI\\+Ru {
  max-width: 500px;
  margin-left: auto;
}
.SocialActivity_socialActivityHead__bxZRO .SocialActivity_wrapper__eI\\+Ru h2 {
  font-family: "Rubik Mono One", sans-serif;
  font-size: 61px;
  font-weight: 400;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 15px;
  color: rgb(255, 255, 255);
}
@media (max-width: 768px) {
  .SocialActivity_socialActivityHead__bxZRO .SocialActivity_wrapper__eI\\+Ru h2 {
    font-size: 30px;
  }
}
.SocialActivity_socialActivityHead__bxZRO .SocialActivity_wrapper__eI\\+Ru p {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/views/socialActivity/SocialActivityHead/SocialActivity.module.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;EACA,iBAAA;AADJ;AAGI;EACE,yCAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,yBAAA;AADN;AAGM;EAVF;IAaI,eAAA;EAFN;AACF;AAKI;EACE,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;AAHN","sourcesContent":[".socialActivityHead {\n\n  .wrapper {\n    max-width: 500px;\n    margin-left: auto;\n\n    h2 {\n      font-family: \"Rubik Mono One\", sans-serif;\n      font-size: 61px;\n      font-weight: 400;\n      line-height: 66px;\n      letter-spacing: 0em;\n      text-align: left;\n      margin-bottom: 15px;\n      color: rgba(255, 255, 255, 1);\n\n      @media (max-width: 768px) {\n\n        // margin-right: 0;\n        font-size: 30px;\n       }\n    }\n\n    p {\n      font-family: Open Sans;\n      font-size: 16px;\n      font-weight: 500;\n      line-height: 26px;\n      letter-spacing: 0em;\n      text-align: left;\n      color: rgba(255, 255, 255, 1);\n\n\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"socialActivityHead": `SocialActivity_socialActivityHead__bxZRO`,
	"wrapper": `SocialActivity_wrapper__eI+Ru`
};
export default ___CSS_LOADER_EXPORT___;
