// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.entitle_energy-title__S5H8H {
  margin-bottom: 40px;
}
.entitle_energy-title__S5H8H img {
  min-width: 100%;
  margin-bottom: 16px;
}
.entitle_energy-title__S5H8H h3 {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  max-width: 500px;
  color: #292e33;
}
.entitle_energy-title__S5H8H h3 span {
  font-size: 46px;
  font-weight: 700;
  line-height: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/energy/entitle.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,mBAAA;AADJ;AAGI;EACI,eAAA;EACA,mBAAA;AADR;AAII;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cClBO;ADgBf;AAIQ;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AAFZ","sourcesContent":["@import \"./../../styles/variables.scss\";\n\n.energy-title {\n    margin-bottom: 40px;\n    \n    img {\n        min-width: 100%;\n        margin-bottom: 16px;\n    }\n\n    h3 {\n        display: flex;\n        align-items: center;\n        gap: 16px;\n        font-size: 24px;\n        font-weight: 700;\n        line-height: 32px;\n        max-width: 500px;\n        color: $titleTextCol;\n\n        span {\n            font-size: 46px;\n            font-weight: 700;\n            line-height: 16px;\n        }\n    }\n}","$titleTextCol: #292e33;\r\n$titleNumCol: #c2c7cc;\r\n\r\n$vacanciesContentColor: #292e33;\r\n\r\n$vacTitleColor: #292e33;\r\n$vacContentColor: #292e33;\r\n\r\n$acordTextColor: #292e33;\r\n$acordTextActiveColor: #fff;\r\n$acordBorderColor: #c2c7cc;\r\n$acordNumberColor: #676d73;\r\n$acordBgActive: #292e33;\r\n\r\n$studyTitleColor: #292e33;\r\n$studyBtnBorderColor: #c2c7cc;\r\n$studyBtnTextColor: #292e33;\r\n$studyCardBorderColor: #c2c7cc;\r\n$studyCardColor: #292e33;\r\n$studyCardLineColor: #292e33;\r\n$studyCardTextColor: #292e33;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"energy-title": `entitle_energy-title__S5H8H`
};
export default ___CSS_LOADER_EXPORT___;
