// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutUs_wrapperContent__yQHCq {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-top: 56px;
  padding-bottom: 56px;
  border-bottom: 1px solid #292e33;
}
.AboutUs_wrapperContent__yQHCq h2 {
  display: none;
}
@media (max-width: 768px) {
  .AboutUs_wrapperContent__yQHCq {
    display: flex;
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/componentsFooter/AboutUs/AboutUs.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,2BAAA;EACA,WAAA;EACA,iBAAA;EACA,oBAAA;EACA,gCAAA;AACF;AAEE;EACE,aAAA;AAAJ;AAOE;EAjBF;IAmBI,aAAA;IACA,sBAAA;EALF;AACF","sourcesContent":[".wrapperContent {\n  display: flex;\n  justify-content: flex-start;\n  width: 100%;\n  padding-top: 56px;\n  padding-bottom: 56px;\n  border-bottom: 1px solid #292e33;\n\n  \n  h2 {\n    display: none;\n  }\n\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n    // display: none;\n    display: flex;\n    flex-direction: column;\n    // margin-bottom: 56px;\n\n    \n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperContent": `AboutUs_wrapperContent__yQHCq`
};
export default ___CSS_LOADER_EXPORT___;
