// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.seed {
  padding: 112px 0 112px 0;
}`, "",{"version":3,"sources":["webpack://./src/views/seed/Seed.scss"],"names":[],"mappings":"AAGA;EACE,wBAAA;AAFF","sourcesContent":["@import \"./../../styles/mixins.scss\";\r\n@import \"./../../styles/variables.scss\";\r\n\r\n.seed {\r\n  padding: 112px 0 112px 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
