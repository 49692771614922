// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DetailsHeader_wrapper__x\\+vJC {
  padding-top: 30px;
}
.DetailsHeader_wrapper__x\\+vJC p {
  font-family: Open Sans;
  font-size: 32px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(242, 242, 243);
  margin-bottom: 30px;
}
.DetailsHeader_wrapper__x\\+vJC h2 {
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(255, 255, 255);
  margin-bottom: 30px;
}
.DetailsHeader_wrapper__x\\+vJC h3 {
  font-family: Open Sans;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/views/LectureDetails/DetailsHeader/DetailsHeader.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AAEQ;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;EACA,mBAAA;AAAZ;AAIQ;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACJ,yBAAA;EACA,mBAAA;AAFR;AAMQ;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;AAJZ","sourcesContent":[".wrapper {\n    padding-top: 30px;\n    \n    \n        p {\n            font-family: Open Sans;\n            font-size: 32px;\n            font-weight: 400;\n            line-height: 24px;\n            letter-spacing: 0em;\n            text-align: left;\n            color: rgba(242, 242, 243, 1);\n            margin-bottom: 30px;\n            \n        }\n    \n        h2 {\n            font-family: Open Sans;\n            font-size: 40px;\n            font-weight: 400;\n            line-height: 40px;\n            letter-spacing: 0em;\n            text-align: left;\n        color: rgba(255, 255, 255, 1);\n        margin-bottom: 30px;\n            \n        }\n    \n        h3 {\n            font-family: Open Sans;\n            font-size: 48px;\n            font-weight: 700;\n            line-height: 56px;\n            letter-spacing: 0em;\n            text-align: left;\n            color: rgba(255, 255, 255, 1);\n            \n        }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DetailsHeader_wrapper__x+vJC`
};
export default ___CSS_LOADER_EXPORT___;
