// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SchebuleHead_wrapper__4QsdX {
  padding-top: 30px;
}
.SchebuleHead_wrapper__4QsdX p {
  font-family: Open Sans;
  font-size: 32px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(242, 242, 243);
  margin-bottom: 30px;
}
.SchebuleHead_wrapper__4QsdX h2 {
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(255, 255, 255);
  margin-bottom: 30px;
}
.SchebuleHead_wrapper__4QsdX h3 {
  font-family: Open Sans;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/views/lectureSchedule/SchebuleHead/SchebuleHead.module.scss"],"names":[],"mappings":"AAAA;EACA,iBAAA;AACA;AAEI;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;EACA,mBAAA;AAAR;AAII;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACJ,yBAAA;EACA,mBAAA;AAFJ;AAMI;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,yBAAA;AAJR","sourcesContent":[".wrapper {\npadding-top: 30px;\n\n\n    p {\n        font-family: Open Sans;\n        font-size: 32px;\n        font-weight: 400;\n        line-height: 24px;\n        letter-spacing: 0em;\n        text-align: left;\n        color: rgba(242, 242, 243, 1);\n        margin-bottom: 30px;\n        \n    }\n\n    h2 {\n        font-family: Open Sans;\n        font-size: 40px;\n        font-weight: 400;\n        line-height: 40px;\n        letter-spacing: 0em;\n        text-align: left;\n    color: rgba(255, 255, 255, 1);\n    margin-bottom: 30px;\n        \n    }\n\n    h3 {\n        font-family: Open Sans;\n        font-size: 48px;\n        font-weight: 700;\n        line-height: 56px;\n        letter-spacing: 0em;\n        text-align: left;\n        color: rgba(255, 255, 255, 1);\n        \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `SchebuleHead_wrapper__4QsdX`
};
export default ___CSS_LOADER_EXPORT___;
