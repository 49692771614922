// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history_period__AoNpi {
  margin-bottom: 4px;
}
.history_period__number__EzbBx {
  display: inline-flex;
  align-items: center;
  position: relative;
  bottom: -10px;
  left: -2px;
}`, "",{"version":3,"sources":["webpack://./src/views/history/history.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,oBAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,UAAA;AACR","sourcesContent":[".period {\n    margin-bottom: 4px;\n\n    &__number {\n        display: inline-flex;\n        align-items: center;\n        position: relative;\n        bottom: -10px;\n        left: -2px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"period": `history_period__AoNpi`,
	"period__number": `history_period__number__EzbBx`
};
export default ___CSS_LOADER_EXPORT___;
