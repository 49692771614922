// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContentLogistics_ContentLogisticsWrapper__GjuO7 {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}
.ContentLogistics_ContentLogisticsWrapper__GjuO7 img {
  position: absolute;
  right: 0px;
  top: 100px;
  width: 50%;
  height: 50%;
}
@media (max-width: 768px) {
  .ContentLogistics_ContentLogisticsWrapper__GjuO7 img {
    position: static;
    float: right;
  }
}

.ContentLogistics_containerList__VRgFm {
  max-width: 440px;
}
.ContentLogistics_containerList__VRgFm .ContentLogistics_description__HdrI2 {
  color: var(--4, #292e33);
  font-family: Open Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 22px;
}
.ContentLogistics_containerList__VRgFm li {
  margin-bottom: 30px;
}
.ContentLogistics_containerList__VRgFm li p {
  display: flex;
  color: var(--4, #292e33);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
}
.ContentLogistics_containerList__VRgFm li p strong {
  padding-left: 25px;
}
.ContentLogistics_containerList__VRgFm li .ContentLogistics_itemWrapper__U4JFH {
  display: flex;
}
.ContentLogistics_containerList__VRgFm li .ContentLogistics_itemWrapper__U4JFH .ContentLogistics_itemIcon__HG-cq {
  margin-right: 14px;
}
.ContentLogistics_containerList__VRgFm .ContentLogistics_descriptionFinish__N4EAV {
  padding: 14px 16px 14px 16px;
  border: 1px solid rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/views/Logistics/ContentLogistics/ContentLogistics.module.scss"],"names":[],"mappings":"AAYA;EACE,kBAAA;EACA,kBAAA;EACA,qBAAA;AAXF;AAeE;EACE,kBAAA;EACA,UAAA;EACA,UAAA;EACA,UAAA;EACA,WAAA;AAbJ;AAgBA;EARE;IASA,gBAAA;IACA,YAAA;EAbA;AACF;;AAiBA;EACE,gBAAA;AAdF;AAgBE;EACE,wBAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAdJ;AAiBE;EACE,mBAAA;AAfJ;AAgBI;EACE,aAAA;EACA,wBAAA;EACA,sBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAdN;AAgBM;EACE,kBAAA;AAdR;AAkBI;EACE,aAAA;AAhBN;AAkBM;EACE,kBAAA;AAhBR;AAqBE;EACE,4BAAA;EACA,oCAAA;AAnBJ","sourcesContent":[".LogisticsWrapper {\n  // position: relative;\n  // background-color: red;\n  // overflow: hidden;\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n  }\n}\n\n.ContentLogisticsWrapper {\n  position: relative;\n  padding-top: 100px;\n  padding-bottom: 100px;\n\n  // width: 300vh;\n\n  img {\n    position: absolute;\n    right: 0px;\n    top: 100px;\n    width: 50%;\n    height: 50%;\n\n    \n@media (max-width: 768px) {\n  position: static;\n  float: right;\n}\n  }\n}\n\n.containerList {\n  max-width: 440px;\n\n  .description {\n    color: var(--4, #292e33);\n    font-family: Open Sans;\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 32px;\n    margin-bottom: 22px;\n  }\n\n  li {\n    margin-bottom: 30px;\n    p {\n      display: flex;\n      color: var(--4, #292e33);\n      font-family: Open Sans;\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 24px;\n      margin-bottom: 20px;\n\n      strong {\n        padding-left: 25px;\n      }\n    }\n\n    .itemWrapper {\n      display: flex;\n\n      .itemIcon {\n        margin-right: 14px;\n      }\n    }\n  }\n\n  .descriptionFinish {\n    padding: 14px 16px 14px 16px;\n    border: 1px solid rgba(255, 255, 255, 1);\n  }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContentLogisticsWrapper": `ContentLogistics_ContentLogisticsWrapper__GjuO7`,
	"containerList": `ContentLogistics_containerList__VRgFm`,
	"description": `ContentLogistics_description__HdrI2`,
	"itemWrapper": `ContentLogistics_itemWrapper__U4JFH`,
	"itemIcon": `ContentLogistics_itemIcon__HG-cq`,
	"descriptionFinish": `ContentLogistics_descriptionFinish__N4EAV`
};
export default ___CSS_LOADER_EXPORT___;
