// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footerWrapper__\\+ihpa {
  background: var(--unnamed, #222426);
  padding-bottom: 74px;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;EAEA,oBAAA;AAAF","sourcesContent":[".footerWrapper {\n  background: var(--unnamed, #222426);\n\n  padding-bottom: 74px;\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerWrapper": `Footer_footerWrapper__+ihpa`
};
export default ___CSS_LOADER_EXPORT___;
