// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.News_NewsWrapper__Bjgkj {
  padding-top: 30px;
}
.News_news-page__J2oGi {
  display: flex;
  gap: 20px;
  margin-bottom: 60px;
}
.News_news-page__title__2l65p {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  gap: 10px;
}
.News_news-page__J2oGi img {
  height: 100%;
  width: 100%;
  max-width: 550px;
  max-height: 550px;
  object-fit: cover;
  cursor: pointer;
}

@media (max-width: 1051px) {
  .News_news-page__J2oGi {
    flex-wrap: wrap;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/news/News.module.scss"],"names":[],"mappings":"AACA;EACI,iBAAA;AAAJ;AAWA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;AATJ;AAWI;EACI,aAAA;EACA,8BAAA;EACA,oBAAA;EACA,SAAA;AATR;AAWI;EACI,YAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;AATR;;AAaA;EACI;IACI,eAAA;EAVN;AACF","sourcesContent":["\n.NewsWrapper {\n    padding-top: 30px;\n\n@media (max-width: 1360px) {\n \n}\n\n@media (max-width: 768px) {\n  \n}\n}\n\n.news-page {\n    display: flex;\n    gap: 20px;\n    margin-bottom: 60px;\n\n    &__title {\n        display: flex;\n        justify-content: space-between;\n        padding-bottom: 15px;\n        gap: 10px;\n    }\n    img {\n        height: 100%;\n        width: 100%;\n        max-width: 550px;\n        max-height: 550px;\n        object-fit: cover;\n        cursor: pointer;\n    }\n}\n\n@media (max-width: 1051px) {\n    .news-page {\n        flex-wrap: wrap;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NewsWrapper": `News_NewsWrapper__Bjgkj`,
	"news-page": `News_news-page__J2oGi`,
	"news-page__title": `News_news-page__title__2l65p`
};
export default ___CSS_LOADER_EXPORT___;
