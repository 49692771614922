// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cropscheme_crop-scheme-item__wtFYO {
  position: relative;
}
.cropscheme_crop-scheme-item__wtFYO svg.cropscheme_measur__bYOmd {
  position: absolute;
  bottom: 3%;
  left: 0;
  z-index: -1;
}
.cropscheme_crop-scheme-item__descr__vE7Cz {
  position: absolute;
  left: 20%;
  top: 40%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 100px;
  height: 100px;
}
.cropscheme_crop-scheme-item__descr__vE7Cz p {
  font-weight: 600;
  line-height: 16px;
  font-size: 16px;
}
.cropscheme_crop-scheme-item__descr__vE7Cz p span {
  font-weight: 400;
}

svg.cropscheme_glue__r7Qvf ~ .cropscheme_crop-scheme-item__descr__vE7Cz {
  top: 50%;
  height: 40px;
}

.cropscheme_question__rqIhB {
  cursor: pointer;
}
.cropscheme_question__rqIhB svg {
  position: absolute;
  right: -10%;
  top: 0;
}
.cropscheme_question__rqIhB .cropscheme_hint__\\+RE0d {
  position: absolute;
  cursor: pointer;
  right: -120%;
  top: -15%;
  opacity: 0;
  clip: rect(0, 0, 0, 0);
  transition: all 0.3s;
}
.cropscheme_question__rqIhB:hover .cropscheme_hint__\\+RE0d {
  opacity: 1;
  clip: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Crop/cropscheme.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,UAAA;EACA,OAAA;EACA,WAAA;AACR;AAEI;EACI,kBAAA;EACA,SAAA;EACA,QAAA;EACA,aAAA;EACA,8BAAA;EACA,sBAAA;EACA,gBAAA;EACA,aAAA;AAAR;AAEQ;EACI,gBAAA;EACA,iBAAA;EACA,eAAA;AAAZ;AAEY;EACI,gBAAA;AAAhB;;AAMA;EACI,QAAA;EACA,YAAA;AAHJ;;AAMA;EACI,eAAA;AAHJ;AAKI;EACI,kBAAA;EACA,WAAA;EACA,MAAA;AAHR;AAMI;EACI,kBAAA;EACA,eAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,sBAAA;EACA,oBAAA;AAJR;AAQQ;EACI,UAAA;EACA,UAAA;AANZ","sourcesContent":[".crop-scheme-item {\n    position: relative;\n\n    svg.measur {\n        position: absolute;\n        bottom: 3%;\n        left: 0;\n        z-index: -1;\n    }\n\n    &__descr {\n        position: absolute;\n        left: 20%;\n        top: 40%;\n        display: flex;\n        justify-content: space-between;\n        flex-direction: column;\n        max-width: 100px;\n        height: 100px;\n\n        p {\n            font-weight: 600;\n            line-height: 16px;\n            font-size: 16px;\n\n            span {\n                font-weight: 400;\n            }\n        }\n    }\n}\n\nsvg.glue~.crop-scheme-item__descr { \n    top: 50%;\n    height: 40px;\n}\n\n.question {\n    cursor: pointer;\n\n    svg {\n        position: absolute;\n        right: -10%;\n        top: 0;\n    }\n\n    .hint {\n        position: absolute;\n        cursor: pointer;\n        right: -120%;\n        top: -15%;\n        opacity: 0;\n        clip: rect(0,0,0,0);\n        transition: all 0.3s;\n    }\n\n    &:hover {\n        .hint {\n            opacity: 1;\n            clip: auto;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crop-scheme-item": `cropscheme_crop-scheme-item__wtFYO`,
	"measur": `cropscheme_measur__bYOmd`,
	"crop-scheme-item__descr": `cropscheme_crop-scheme-item__descr__vE7Cz`,
	"glue": `cropscheme_glue__r7Qvf`,
	"question": `cropscheme_question__rqIhB`,
	"hint": `cropscheme_hint__+RE0d`
};
export default ___CSS_LOADER_EXPORT___;
