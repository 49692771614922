// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contacts_contactsWrapper__MvFrq {
  display: flex;
  justify-content: space-between;
  padding-bottom: 38px;
}
@media (max-width: 768px) {
  .Contacts_contactsWrapper__MvFrq {
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/contacts/Contacts.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EAEA,oBAAA;AAAF;AAME;EAVF;IAYI,sBAAA;EAJF;AACF","sourcesContent":[".contactsWrapper {\n  display: flex;\n  justify-content: space-between;\n  // background-color: green;\n  padding-bottom: 38px;\n  // flex-wrap: wrap;\n\n  @media (max-width: 1360px) {\n  }\n\n  @media (max-width: 768px) {\n    // display: flex;\n    flex-direction: column;\n    // background-color: tomato;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactsWrapper": `Contacts_contactsWrapper__MvFrq`
};
export default ___CSS_LOADER_EXPORT___;
