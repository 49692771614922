import { AxiosInstance } from "./axios";

export const getLocationList = async () =>{
    const response = await AxiosInstance.get(`/locations?pagination=false`);
    return response.data;
}

// export const getSpecialtiesList = async () =>{
//     const response = await AxiosInstance.get(`/specialties?pagination=false`);
//     return response.data;
// }

export const getUserInfo= async (id) =>{
    const response = await AxiosInstance.get(`/users/${id}`);
    return response.data;
}

export const getSubscriberInfo= async (name, event) =>{
    const response = await AxiosInstance.get(`/subscribers?name=${name}&events.id=${event}`);
    return response.data;
}

export const getEventId = async (id) =>{
    const response = await AxiosInstance.get(`/events/${id}`);
    return response.data;
}

export const getEventLocations = async (id) =>{
    const response = await AxiosInstance.get(`/event_locations?event.id=${id}`);
    return response.data;
}


export const getMaterialsList = async (id) =>{
    const response = await AxiosInstance.get(`/materials?events.id=${id}`);
    return response.data;
}