// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logistics_LogisticsWrapper__4OpMG {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/views/Logistics/Logistics.module.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF","sourcesContent":["\n.LogisticsWrapper {\n  position: relative;\n\n\n\n\n\n@media (max-width: 1360px) {\n \n}\n\n@media (max-width: 768px) {\n  \n}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LogisticsWrapper": `Logistics_LogisticsWrapper__4OpMG`
};
export default ___CSS_LOADER_EXPORT___;
