// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SocialActivity_NewsWrapper__MLNSY {
  padding-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/views/socialActivity/SocialActivity.module.scss"],"names":[],"mappings":"AAEA;EAGE,iBAAA;AAHF","sourcesContent":["\n\n.NewsWrapper {\n  // position: relative;\n  // background-color: red;\n  padding-top: 30px;\n\n\n@media (max-width: 1360px) {\n \n}\n\n@media (max-width: 768px) {\n  \n}\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NewsWrapper": `SocialActivity_NewsWrapper__MLNSY`
};
export default ___CSS_LOADER_EXPORT___;
