// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: absolute;
  left: 35%;
  max-width: 750px;
  width: 100%;
  background-color: #292E33;
  color: #FFF;
  z-index: 2;
  padding: 32px;
}
.modal h3 {
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  display: inline-flex;
  gap: 50px;
  margin-bottom: 20px;
}
.modal h3 svg {
  cursor: pointer;
}
.modal p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 15px;
}

.data {
  display: flex;
  flex-direction: column;
  margin-top: 56px;
}
.data__element {
  display: flex;
  gap: 16px;
  align-items: center;
}
.data__element p {
  width: 200px;
}
.data__element span {
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/History/HistoryModal/historymodal.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;EACA,gBAAA;EACA,WAAA;EACA,yBAAA;EACA,WAAA;EACA,UAAA;EACA,aAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;EACA,SAAA;EACA,mBAAA;AACR;AACQ;EACI,eAAA;AACZ;AAGI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AADR;;AAKA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;AAFJ;AAII;EACI,aAAA;EACA,SAAA;EACA,mBAAA;AAFR;AAIQ;EACI,YAAA;AAFZ;AAKQ;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AAHZ","sourcesContent":[".modal {\n    position: absolute;\n    left: 35%;\n    max-width: 750px;\n    width: 100%;\n    background-color: #292E33;\n    color: #FFF;\n    z-index: 2;\n    padding: 32px;\n\n    h3 {\n        font-size: 40px;\n        font-weight: 700;\n        line-height: 40px;\n        display: inline-flex;\n        gap: 50px;\n        margin-bottom: 20px;\n\n        svg {\n            cursor: pointer;\n        }\n    }\n\n    p {\n        font-size: 16px;\n        font-weight: 400;\n        line-height: 24px;\n        margin-bottom: 15px;\n    }\n}\n\n.data {\n    display: flex;\n    flex-direction: column;\n    margin-top: 56px;\n\n    &__element {\n        display: flex;\n        gap: 16px;\n        align-items: center;\n\n        p {\n            width: 200px;\n        }\n\n        span {\n            font-size: 32px;\n            font-weight: 700;\n            line-height: 24px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
