// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report_report__dR\\+7B {
  padding: 65px 0;
}
.report_report__dR\\+7B .report_filterWrapper__tcyzr ul {
  display: flex;
  gap: 16px;
  margin-bottom: 64px;
  flex-wrap: wrap;
}
.report_report__dR\\+7B .report_filterWrapper__tcyzr ul li button {
  width: 120px;
  min-height: 40px;
  background-color: transparent;
  border: 1px solid rgb(194, 199, 204);
  border-radius: 2px;
  color: #45494d;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.report_report__dR\\+7B .report_filterWrapper__tcyzr ul li .report_active__button__3r69v {
  background-color: rgb(194, 199, 204);
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/views/reports/report.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AAEI;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;AAAR;AAGQ;EACI,YAAA;EACA,gBAAA;EACA,6BAAA;EACA,oCAAA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AADZ;AAIQ;EACI,oCAAA;EACA,WAAA;AAFZ","sourcesContent":[".report {\n    padding: 65px 0;\n    // ==========================================\n    .filterWrapper {\n    ul {\n        display: flex;\n        gap: 16px;\n        margin-bottom: 64px;\n        flex-wrap: wrap;\n\n        li {\n        button {\n            width: 120px;\n            min-height: 40px;\n            background-color: transparent;\n            border: 1px solid rgba(194, 199, 204, 1);\n            border-radius: 2px;\n            color: #45494d;\n            cursor: pointer;\n            user-select: none;\n            font-family: Open Sans;\n            font-size: 16px;\n            font-weight: 600;\n            line-height: 24px;\n            letter-spacing: 0em;\n            text-align: center;\n        }\n\n        .active__button {\n            background-color: rgba(194, 199, 204, 1);\n            color: #fff;\n        }\n        }\n    }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"report": `report_report__dR+7B`,
	"filterWrapper": `report_filterWrapper__tcyzr`,
	"active__button": `report_active__button__3r69v`
};
export default ___CSS_LOADER_EXPORT___;
