// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calculate_calculate-soil__8nTSL {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}
.calculate_calculate-soil__8nTSL p {
  max-width: 400px;
  margin-bottom: 30px;
}
.calculate_calculate-soil__8nTSL p span {
  color: #292E33;
}
.calculate_calculate-soil__8nTSL span {
  font-weight: 700;
  color: #676D73;
}
.calculate_calculate-soil__inner__Sd6V7 {
  display: flex;
  align-items: center;
  gap: 16px;
}
.calculate_calculate-soil__8nTSL input {
  font-family: Open Sans;
  color: #676D73;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  padding: 5px 15px;
  max-width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/components/Soil/CalculateSoil/calculate.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;AACJ;AACI;EACI,gBAAA;EACA,mBAAA;AACR;AAAQ;EACI,cAAA;AAEZ;AAEI;EACI,gBAAA;EACA,cAAA;AAAR;AAGI;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AADR;AAII;EACI,sBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;AAFR","sourcesContent":[".calculate-soil {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 100px;\n\n    p {\n        max-width: 400px;\n        margin-bottom: 30px;\n        span {\n            color: #292E33;\n        }\n    }\n\n    span {\n        font-weight: 700;\n        color: #676D73;\n    }\n\n    &__inner {\n        display: flex;\n        align-items: center;\n        gap: 16px;\n    }\n\n    input {\n        font-family: Open Sans;\n        color: #676D73;\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 14px;\n        padding: 5px 15px;\n        max-width: 120px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calculate-soil": `calculate_calculate-soil__8nTSL`,
	"calculate-soil__inner": `calculate_calculate-soil__inner__Sd6V7`
};
export default ___CSS_LOADER_EXPORT___;
